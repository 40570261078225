/* eslint-disable max-lines */
import { Dispatch } from 'react';
import moment from 'moment';
import { setError } from 'redux/error/errorSlice';
import { handlePostApiFailureMessage } from 'redux/organizations/Reducers/organizationSlice';
import { Error_Type_Enum } from 'utils/enum';
// import { BASE_URL, PULSE_BASE_URL } from '../../../../../../libs/shared-ui/src/lib/api';
import {
  handleBundleApiDataFail,
  handleBundleData,
  handleCreateBundleApiDataFail,
  handleCreateBundleApiDataFailConstants,
  handleCreateBundleApiDataFailToggle,
  handleCreateBundleApiPostDataLoading,
  handleCreateBundleApiPostDataSuccess,
  handleCreateBundleGracePeriodData,
  handleCreateBundleGracePeriodDataFail,
  handleCreateSoftwareBundleStatusApiFail,
  handleCreateSoftwareBundleStatusData,
  handleEditPostBundleDataLoading,
  handlePostUniqueCatalogueValidation,
  handlePostUniqueNameValidation,
  handleSingleBundleEditData,
  handleSingleBundleEditDataFail,
  hanldeEditBundlePostApi,
  hanldeEditBundlePostApiFail,
  handleBundleApiDataLoading,
  setAddonsIsLoading,
  setAddonsData,
  setUnlinkedAddonsData,
  handleUniqueValueLoading,
  setAddonData,
  setAddonIsLoading,
  createLicenseAddonPending,
  handleCreateAddonResponse,
  setFeaturesIsLoading,
  setFeaturesData,
  setPostFeaturesLoading,
  setPostFeaturesSuccess,
  setPostFeaturesFail,
  setFeatureIsLoading,
  setFeatureData,
  updateFeatureAvailability,
  setFeatureUpdating,
} from '../Reducers/SoftwareBundleSlice';
import { userRequest } from 'api';
import { SoftwareBundle } from 'types/SoftwareBundle';
import { Addon, Feature as AddonFeature } from 'types/add-on';
import { Feature as LicenseFeature, Bundle as FeatureBundle, PostFeature } from 'types/feature';
import { LicenseTier } from 'types/LicenseTier';
import { parseDaysIntoYears } from '@exo/shared-ui';

interface IGetAllAssets {
  type: boolean | string;
}
export const getBundleStatus = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));

    const res = await userRequest.get(`licenses/status-list/`);

    if (res && res.status === 200) {
      dispatch(handleCreateSoftwareBundleStatusData(res.data.data));
      dispatch(handleCreateSoftwareBundleStatusApiFail(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(handleCreateSoftwareBundleStatusApiFail(true));
  }
};
export const getBundleData =
  (pageNumber = 1, searchText = '') =>
  async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(handleBundleApiDataLoading());
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.get(
        `licenses/bundles/?page=${pageNumber}&search=${encodeURIComponent(searchText)}`,
      );

      if (res && res.status === 200) {
        res.data.data = res?.data?.data?.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            status: item.status,
            enabled: item.enabled,
            availabilityDate: item.availability_date,
            catalogueNumber: item.catalog_number,
            licenseTier: item.license_tier,
            gracePeriodDays: item.grace_period_days,
            description: item.description,
          } as SoftwareBundle;
        });

        dispatch(handleBundleData(res.data));
        dispatch(handleBundleApiDataFail(false));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
      dispatch(handleBundleApiDataFail(true));
    }
  };
export const postSoftwareBundleData = (postData: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(handleCreateBundleApiPostDataLoading(true));
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`licenses/bundles/`, postData);
    if (res && (res.data.status_code == 200 || res.data.status_code == 201)) {
      dispatch(handleCreateBundleApiPostDataSuccess(res.data.result));
      dispatch(handleCreateBundleApiDataFailConstants(Error_Type_Enum.Success));
      dispatch(handleCreateBundleApiDataFail(false));
      dispatch(handleCreateBundleApiDataFailToggle(false));
      dispatch(handleCreateBundleApiPostDataLoading(false));
      // dispatch(postApiSuccess(true))
    } else {
      dispatch(handleCreateBundleApiDataFail(true));
      dispatch(handleCreateBundleApiDataFailConstants(Error_Type_Enum.Fail));
      dispatch(handleCreateBundleApiPostDataLoading(false));
      dispatch(handleCreateBundleApiDataFailToggle(true));
      dispatch(handlePostApiFailureMessage(res?.data?.message[0]));
    }
  } catch (error: any) {
    // dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(handleCreateBundleApiDataFailToggle(true));
    dispatch(handlePostApiFailureMessage(error?.response?.data?.message[0]));
    dispatch(handleCreateBundleApiDataFail(true));
    dispatch(handleCreateBundleApiDataFailConstants(Error_Type_Enum.Fail));
  }
};
export const getSingleBundleEditData = (id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(handleEditPostBundleDataLoading(true));
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`licenses/bundles/${id}/`);
    if (res && res.status === 200) {
      dispatch(handleSingleBundleEditData(res.data.data));
      dispatch(handleSingleBundleEditDataFail(false));
      dispatch(handleEditPostBundleDataLoading(false));
    } else {
      dispatch(handleEditPostBundleDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(handleSingleBundleEditDataFail(true));
    dispatch(handleEditPostBundleDataLoading(false));
  }
};
export const editBundleData = (postedData: any, id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(handleEditPostBundleDataLoading(true));
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.put(`licenses/bundles/${id}/`, postedData);
    if (res && (res.status === 200 || res.status === 201)) {
      dispatch(hanldeEditBundlePostApi(res.data.result));
      dispatch(hanldeEditBundlePostApiFail(Error_Type_Enum.Success));
      dispatch(handleEditPostBundleDataLoading(false));
    } else {
      dispatch(handleEditPostBundleDataLoading(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(hanldeEditBundlePostApiFail(Error_Type_Enum.Fail));
    dispatch(handleEditPostBundleDataLoading(false));
  }
};

export const getBundleGracePeriod = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(`licenses/grace-period-list/`);
    if (res && res.status === 200) {
      dispatch(handleCreateBundleGracePeriodData(res.data.data));
      dispatch(handleCreateBundleGracePeriodDataFail(false));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(handleCreateBundleGracePeriodDataFail(true));
  }
};
export const postUniqueNameBundleValue = (PostUniqueValues: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(handleUniqueValueLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(
      `licenses/bundles/validate/?field_name=${'name'}&field_value=${PostUniqueValues?.field_value}`,
    );
    if (res && res.data.status_code === 404) {
      // dispatch(fetchDeviceTypeInternalNameUniqueValidation(PostUniqueValues.field_value));
      dispatch(handlePostUniqueNameValidation(false));
    } else if (res && res.data.status_code === 200) {
      dispatch(handlePostUniqueNameValidation(true));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(handlePostUniqueNameValidation(false));
  }
};
export const postUniqueCatalogueBundleValue = (PostUniqueValues: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(handleUniqueValueLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get(
      `licenses/bundles/validate/?field_name=${'catalog_number'}&field_value=${PostUniqueValues?.field_value}`,
    );
    if (res && res.data.status_code === 404) {
      // dispatch(fetchDeviceTypeInternalNameUniqueValidation(PostUniqueValues.field_value));
      dispatch(handlePostUniqueCatalogueValidation(false));
    } else if (res && res.data.status_code === 200) {
      dispatch(handlePostUniqueCatalogueValidation(true));
    }
  } catch {
    dispatch(handlePostUniqueCatalogueValidation(false));
  }
};

// License Add-ons

export const fetchLicenseAddons =
  (pageNumber = 1, searchText = '') =>
  async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(setAddonsIsLoading());
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.get(`/licenses/addons?page=${pageNumber}&search=${encodeURIComponent(searchText)}`);
      if (res && res.status === 200) {
        res.data.data = res?.data?.data?.map((item: any) => {
          const supportedTiers = item.license_tiers.map((tier: any) => {
            return { id: tier.id, tierCode: tier.tier_code, tierName: tier.tier_name } as LicenseTier;
          });

          return {
            id: item.id,
            name: item.name,
            catalogNumber: item.catalog_number ? item.catalog_number.toUpperCase() : '-',
            availabilityDate: item.availability_date ? moment(item.availability_date).format('ll') : '-',
            gracePeriodDays: parseDaysIntoYears(item.grace_period_days),
            description: item.description,
            status: item.status,
            supportedTiers: supportedTiers,
            // features: features,
          } as Addon;
        });

        dispatch(setAddonsData(res.data));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
    }
  };

export const fetchLicenseUnLinkedAddons = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const url = '/licenses/addons?unlinked_addons=true';
    const res = await userRequest.get(url);
    if (res && res.status === 200) {
      res.data.data = res?.data?.data?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          catalogNumber: item.catalog_number,
        } as Addon;
      });

      dispatch(setUnlinkedAddonsData(res.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
  }
};

export const fetchLicenseAddon = (id: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(setAddonIsLoading());
  try {
    const res = await userRequest.get(`licenses/addons/${id}`);
    if (res && res.status === 200) {
      const data = res.data?.data;
      const mappedAddon = {
        id: data.id,
        name: data.name,
        catalogNumber: data.catalog_number ? data.catalog_number.toUpperCase() : '-',
        availabilityDate: data.availability_date ? moment(data.availability_date).format('ll') : '-',
        gracePeriodDays: parseDaysIntoYears(data.grace_period_days),
        description: data.description,
        status: {
          id: data.status.id,
          status: data.status.status,
          colorCode: data.status.color_code,
        },
        supportedTiers: data.license_tiers.map((tier: any) => ({
          id: tier.id,
          tierCode: tier.tier_code,
          tierName: tier.tier_name,
        })),
        features: data.features
          ? data.features.map((feature: any) => ({
              id: feature.id,
              name: feature.name,
              groupName: feature.group_name,
              description: feature.description,
              isEnabled: feature.enabled,
            }))
          : [],
      };
      dispatch(setAddonData(mappedAddon));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
  }
};

export const createNewLicenseAddon = (addOnInfo: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(createLicenseAddonPending());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post(`licenses/addons/`, addOnInfo);
    if ((res && res.data.status_code === 200) || res.data.status_code === 201) {
      dispatch(handleCreateAddonResponse({ status: Error_Type_Enum.Success, errorMessage: '' }));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(handleCreateAddonResponse({ status: Error_Type_Enum.Fail, errorMessage: error?.response?.data?.message }));
  }
};

// License Features

export const fetchLicenseFeatures =
  (pageNumber = 1, searchText = '') =>
  async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(setFeaturesIsLoading());
    try {
      dispatch(setError({ isError: false, errorMessage: '' }));
      const res = await userRequest.get(
        `/licenses/features?page=${pageNumber}&search=${encodeURIComponent(searchText)}`,
      );
      if (res && res.status === 200) {
        res.data.data = res?.data?.data?.map((item: any) => {
          const addon = item.addon
            ? ({
                id: item.addon.id,
                name: item.addon.name,
                catalogNumber: item.addon.catalog_number,
                availabilityDate: item.addon.availability_date,
                gracePeriodDays: item.addon.grace_period_days,
                description: item.addon.description,
                status: item.addon.status,
              } as Addon)
            : null;
          const bundleTiers = item.license_bundles.map((bundle: any) => bundle.name).join(', ');
          return {
            id: item.id,
            name: item.name,
            code: item.code,
            groupName: item.group_name || '-',
            description: item.description,
            available: item.enabled,
            bundles: bundleTiers,
            featureAddon: addon,
            isUpdating: false,
            controlledByPulse: item.controlled_by_pulse,
          } as LicenseFeature;
        });
        dispatch(setFeaturesData(res.data));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
    }
  };

export const postLicenseFeatures = (payload: PostFeature) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(setPostFeaturesLoading());
  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.post('licenses/features', payload);
    if ((res && res.data.status_code === 200) || res.data.status_code === 201) {
      dispatch(setPostFeaturesSuccess(true));
      dispatch(setPostFeaturesFail({ isFail: false, errorMessage: '' }));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(setPostFeaturesFail({ isFail: true, errorMessage: error?.response?.data?.message }));
    dispatch(setPostFeaturesSuccess(false));
  }
};
export const fetchLicenseFeature = (featureId: any) => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(setFeatureIsLoading());
  try {
    const res = await userRequest.get(`licenses/features/${featureId}`);
    if (res && res.status === 200) {
      const data = res.data?.data;
      const bundles = data.license_bundles.map((bundle: any) => {
        return {
          id: bundle.id,
          name: bundle.name,
        } as FeatureBundle;
      });
      const addon = data.addon
        ? ({
            id: data.addon.id,
            name: data.addon.name,
            catalogNumber: data.addon.catalog_number,
            availabilityDate: data.addon.availability_date,
            gracePeriodDays: data.addon.grace_period_days,
            description: data.addon.description,
            status: data.addon.status,
          } as Addon)
        : null;
      const mappedFeature = {
        id: data.id,
        name: data.name,
        code: data.code,
        groupName: data.group_name,
        description: data.description,
        available: data.enabled,
        bundles: bundles,
        featureAddon: addon,
        controlledByPulse: data.controlled_by_pulse,
      } as LicenseFeature;

      dispatch(setFeatureData(mappedFeature));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
  }
};
export const updateLicenseFeature =
  (featureId: number, isEnabled: boolean) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(setFeatureUpdating({ id: featureId }));
    try {
      const res = await userRequest.put(`licenses/features/${featureId}`, {
        enabled: isEnabled,
      });

      if (res && res.data.status_code == 200) {
        dispatch(updateFeatureAvailability({ id: featureId }));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
    }
  };
