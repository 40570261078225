import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { UserInfo, User, UserCheckResponse } from '../types/types';
import { SharedUiState } from './hooks';
import { resetPassword } from './login.thunk';

export type LoginState = {
  singleSignOnUser: UserInfo;
  singleSignOnLoading: boolean;
  singleSignOnError: boolean | null;
  userWithPassword: UserInfo[];
  user: User | null;
  userLoading: boolean;
  userError: boolean;
  isSsoEnabledUser: boolean;
  isPasswordRequired: boolean;
  apiEndpoint: string;
  isInvalidUser: boolean;
  userErrorMessage: string;
  createUserSessionError: string;
  createUserSessionSuccess: boolean;
  createUserSessionLoading: boolean;
  baseUrl: string;
  resetPasswordLoading: boolean;
  resetPasswordError: boolean;
  resetPasswordSuccess: boolean;
  ownerOrganizations: UserInfo[] | [];
  selectedOrg: UserInfo | null;
  isExoWorks: boolean;
};

const initialUser = {
  org_id: '',
  service_env: '',
  auth_info: {
    auth0_client_id_mobile: '',
    auth0_client_id_web: '',
    auth0_domain: '',
  },
  auth_profiles: [],
  service_info: {
    web_url: '',
    api_url: '',
  },
  org_language_code: '',
  org_country_code: '',
  org_logo_url: '',
  org_name: '',
};

export const initialState: LoginState = {
  singleSignOnUser: initialUser,
  singleSignOnLoading: false,
  singleSignOnError: false,
  userWithPassword: [],
  user: null,
  userLoading: false,
  userError: false,
  isSsoEnabledUser: false,
  isPasswordRequired: false,
  apiEndpoint: '',
  isInvalidUser: false,
  userErrorMessage: '',
  createUserSessionLoading: false,
  createUserSessionError: '',
  createUserSessionSuccess: false,
  baseUrl: '',
  resetPasswordLoading: false,
  resetPasswordError: false,
  resetPasswordSuccess: false,
  ownerOrganizations: [],
  selectedOrg: null,
  isExoWorks: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setBaseUrl: (state, action: PayloadAction<string>) => {
      state.baseUrl = action.payload;
    },
    fetchSingleSignOnCheckLoading: (state) => {
      state.singleSignOnLoading = true;

      state.userError = false;
      state.isSsoEnabledUser = false;
      state.isPasswordRequired = false;
      state.isInvalidUser = false;
      state.userErrorMessage = '';
    },
    fetchSingleSignOnCheckSuccess: (state, action: PayloadAction<UserCheckResponse>) => {
      state.singleSignOnLoading = false;
      state.userError = false;
      state.singleSignOnUser = action.payload.data;
      state.isSsoEnabledUser = action.payload.isSsoEnabledUser;
      let apiUrl = action.payload.data.service_info?.api_url;

      apiUrl = apiUrl.includes('https://') ? apiUrl : `https://${apiUrl}`;
      localStorage.setItem('exo_api_url', apiUrl);
      localStorage.setItem('exo_org_id', action.payload.data.org_id);
      localStorage.setItem('exo_user_email', action.payload.userId);
    },

    setIsSsoEnabledUser: (state, action: PayloadAction<boolean>) => {
      state.isSsoEnabledUser = action.payload;
    },
    resetUserErrorMsg: (state) => {
      state.userErrorMessage = '';
    },
    fetchSingleSignOnCheckFail: (state, action: PayloadAction<string>) => {
      state.singleSignOnLoading = false;
      state.singleSignOnError = true;
      state.userErrorMessage = action.payload;
    },
    fetchSsoUserLoading: () => {},
    fetchSsoUserSuccess: () => {},
    fetchSsoUserFail: () => {},
    fetchUserLoading: (state) => {
      state.userLoading = true;
    },
    fetchUserSuccess: (state, { payload }) => {
      state.userLoading = false;
      state.userError = false;
      state.user = payload;
      localStorage.setItem('TOKEN', payload.access_token);
      localStorage.setItem('exo_user_email', payload.user_id);
    },
    fetchUserFail: (state, { payload }) => {
      state.userErrorMessage = payload.msg;
    },
    logoutHandler: (state) => {
      // FIXME: Importing the logout function from one lib to another breaks the app
      // https://github.com/nrwl/nx/issues/10082
      state.singleSignOnUser = initialUser;
      localStorage.clear();
    },
    createUserSessionLoading: (state, action: PayloadAction<boolean>) => {
      state.createUserSessionLoading = action.payload;
    },
    createUserSessionFail: (state, action: PayloadAction<string>) => {
      state.createUserSessionError = action.payload;
      state.createUserSessionLoading = false;
      state.createUserSessionSuccess = false;
    },
    createUserSessionSuccess: (state, action: PayloadAction<boolean>) => {
      state.createUserSessionSuccess = action.payload;
      state.createUserSessionLoading = false;
      state.createUserSessionError = '';
    },
    resetPasswordError: (state) => {
      state.userError = false;
    },
    setResetPasswordSuccess: (state, action: PayloadAction<boolean>) => {
      state.resetPasswordSuccess = action.payload;
    },
    fetchOwnerOrganizations: (state, action) => {
      state.ownerOrganizations = action.payload;
      state.singleSignOnLoading = false;
    },
    fetchSelectedOrganization: (state, action) => {
      state.selectedOrg = action.payload;
      state.singleSignOnLoading = false;
    },
    getEnv: (state, action) => {
      state.isExoWorks = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.userError = false;
        state.resetPasswordLoading = true;
        state.resetPasswordError = false;
        state.resetPasswordSuccess = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPasswordLoading = false;
        state.resetPasswordError = false;
        state.resetPasswordSuccess = true;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPasswordLoading = false;
        state.resetPasswordError = true;
        state.resetPasswordSuccess = false;
      });
  },
});

export const {
  fetchSingleSignOnCheckLoading,
  fetchSingleSignOnCheckSuccess,
  setIsSsoEnabledUser,
  fetchSingleSignOnCheckFail,
  fetchSsoUserLoading,
  fetchSsoUserSuccess,
  fetchSsoUserFail,
  fetchUserFail,
  fetchUserLoading,
  fetchUserSuccess,
  logoutHandler,
  resetPasswordError,
  createUserSessionLoading,
  createUserSessionFail,
  createUserSessionSuccess,
  setBaseUrl,
  setResetPasswordSuccess,
  fetchOwnerOrganizations,
  fetchSelectedOrganization,
  getEnv,
  resetUserErrorMsg,
} = loginSlice.actions;

// Selectors
const selectLoginState = (state: SharedUiState) => state.login;
const selectors = {
  selectCurrentUser: createSelector(selectLoginState, (loginState) => loginState.singleSignOnUser),
};

export const { selectCurrentUser } = selectors;

export const loginReducer = loginSlice.reducer;
