import { userRequest } from 'api';
import { Dispatch } from 'react';
import {
  setFeaturesList,
  fetchFeaturesLoading,
  updateFeatureAvailablity,
  setFeatureUpdating,
  isUpdatedStatusFail,
} from '../Reducers/OrgFeaturesSlice';
import { setError } from 'redux/error/errorSlice';

export interface apiConfig {
  baseUrl: string;
  method: string;
}
interface IGetAllAssets {
  type: boolean | string;
}

export const getFeaturesList =
  (orgId: string, searchText = '', pageNumber = 1) =>
  async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(fetchFeaturesLoading());
    try {
      const res = await userRequest.get(
        `organizations/${orgId}/licenses/features?page=${pageNumber}&search=${searchText}`,
      );
      if (res && res.status == 200) {
        dispatch(setFeaturesList(res.data));
      }
    } catch (error: any) {
      dispatch(setError({ isError: true, errorMessage: error?.message }));
    }
  };

export const updateFeature =
  (orgId: string, featureId: number, isEnabled: boolean) => async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(setFeatureUpdating({ id: featureId }));
    try {
      const res = await userRequest.put(`organizations/${orgId}/licenses/features/${featureId}`, {
        enabled: isEnabled,
      });
      if (res && res.status == 200) {
        dispatch(updateFeatureAvailablity({ id: featureId }));
      }
    } catch (error: any) {
      dispatch(isUpdatedStatusFail());
      dispatch(setError({ isError: true, errorMessage: error?.message }));
    }
  };
