import React from 'react';
import { Text, Flex, Box, Icon, useColorModeValue } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { customColor } from '../../../../../assets/styles/colors';
// import OrganizationCreateModal from '../OrganizationCreatePage/OrganizationCreateModal/OrganizationCreateModal';
import AddOrgModalPopup from 'pages/organizations/addOrganizations/addOrgModalPopup';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { userAccessConst } from 'utils/constants/userAccessConst';
import { AddIcon } from 'assets/icons/icons';
import { toggleAddOrgModal } from 'redux/organizations/Reducers/organizationSlice';
import { getBundles, getLanguage } from 'redux/organizations/Actions/organizationActions';

export default function OrganizationTitle() {
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const colors = customColor();
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');
  const addBtnColor = useColorModeValue('white', 'black');
  const addBtnFill = useColorModeValue('white', 'transparent');
  const addBtnStroke = useColorModeValue('white', 'none');

  const handleModalOpen = () => {
    dispatch(toggleAddOrgModal(true));
    dispatch(getBundles());
    dispatch(getLanguage());
    // dispatch(resetOrganizationCreateActions());
  };

  return (
    <Flex alignItems="center" h="50px">
      {userPermissions.organizations?.includes(userAccessConst.organization.addOrg) ? (
        <>
          <Text
            mr={{ xs: '3' }}
            fontSize={{ xs: '22px', xl: '24px' }}
            fontWeight="medium"
            data-testid="org-title"
            color={colors.textColor}
          >
            <FormattedMessage id="new_organization.message" />
          </Text>

          <Box data-testid="org-modal">
            <Flex alignItems="center">
              <Icon
                onClick={handleModalOpen}
                w="24px"
                h="24px"
                color={addBtnColor}
                fill={addBtnFill}
                _hover={{
                  color: 'url("#BlueGradient")',
                  cursor: 'pointer',
                  stroke: 'white',
                  fill: 'url("#BlueGradient")',
                  '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                  '&:hover>svg>circle': { stroke: addBtnStroke },
                }}
              >
                <AddIcon stroke={primarblackAndWhiteColorMode} />
              </Icon>
            </Flex>
            <AddOrgModalPopup />
          </Box>
        </>
      ) : (
        <Text fontSize="24px" fontWeight="400" color="rgba(51, 51, 51, 1)">
          <FormattedMessage id="organizations.message" />
        </Text>
      )}
    </Flex>
  );
}
