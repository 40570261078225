import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Menu, MenuButton, Icon, MenuList, MenuItem, Text } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import { InputLabel } from 'components/InputField';
import React from 'react';

const CustomSelect = ({ handleSelectChange, options, selectedValue }: any) => {
  const colors = customColor();
  return (
    <Box>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              isActive={isOpen}
              as={Button}
              minW={{ xs: '110px', md: '130px' }}
              borderRadius="0px"
              variant="flushed"
              data-testid="info-license-info"
              _hover={{
                backgroundColor: 'transparent',
              }}
              _active={{
                backgroundColor: 'transparent',
              }}
              rightIcon={
                isOpen ? (
                  <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                ) : (
                  <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                )
              }
            >
              <Box display="flex">
                <Text fontSize="16px" fontWeight="400" color={colors.primaryColor}>
                  {options && options.find((item: any) => item.id == selectedValue)?.name}
                </Text>
              </Box>
            </MenuButton>
            <MenuList minW={{ xs: '110px', md: '120px' }} zIndex="9999">
              {options &&
                options.map((state: any) => (
                  <MenuItem
                    id={state.id}
                    key={state.id}
                    value={state.id}
                    name={state.same}
                    backgroundColor="transparent"
                    _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                    onClick={() => handleSelectChange(state.id)}
                  >
                    <Box display="flex">{state.name}</Box>
                  </MenuItem>
                ))}
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default CustomSelect;
