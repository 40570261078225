import { useAppSelector } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';
import { AbsoluteCenter, Box, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import CustomTable from 'components/customTable/CustomTable';
import { customColor } from 'assets/styles/colors';
import { Feature } from 'types/feature';
import { updateLicenseFeature } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import { userAccessConst } from 'utils/constants/userAccessConst';

interface FeatureListProps {
  searchText: string;
}

const FeaturesListView = ({ searchText }: FeatureListProps) => {
  const navigate = useNavigate();
  const colors = customColor();

  const featuresData = useAppSelector((state) => state.softwareBundles.featuresData);
  const features: Feature[] = featuresData?.data || [];
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const hasAvailableEditAccess = userPermissions.bundles?.includes(userAccessConst.bundle.addBundle);
  const handleNavigateFromChild = (row: any, field: any) => {
    if (field === 'name') {
      navigate(`features/${row.id}/info`);
    } else if (field === 'featureAddon') {
      navigate(`add-ons/${row.featureAddon.id}/info`);
    } else {
      navigate(``);
    }
  };

  const showNoMatchFound = searchText.length > 0 && !features?.length;

  const columns = [
    {
      field: 'name',
      header: <FormattedMessage id="name.message" />,
      link: true,
      isCapitalize: true,
    },
    {
      field: 'groupName',
      header: <FormattedMessage id="feature_group.message" />,
      link: false,
    },
    {
      field: 'code',
      header: <FormattedMessage id="feature_code.message" />,
      link: false,
    },
    {
      field: 'bundles',
      header: <FormattedMessage id="feature_bundle.message" />,
      link: false,
    },
    {
      field: 'featureAddon',
      header: <FormattedMessage id="feature_addon.message" />,
      link: true,
      isCapitalize: false,
    },
    {
      field: 'available',
      header: <FormattedMessage id="available.message" />,
      link: false,
      hasEditAccess: hasAvailableEditAccess,
    },
  ];
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pr="20px"
      overflowY="scroll"
      h="calc(100vh - 275px)"
      data-testid="license-features-data"
      ml="-15px"
    >
      {showNoMatchFound && (
        <AbsoluteCenter display="flex" justifyContent="center" w="100%">
          <Text
            opacity="0.5"
            color={colors.textColor}
            fontSize={{ xs: '16px', lg: '18px' }}
            data-testid="no-match-found"
          >
            <FormattedMessage id="no_matches_found.message" />
          </Text>
        </AbsoluteCenter>
      )}
      {features?.length > 0 && (
        <CustomTable
          data={features}
          columns={columns}
          route={''}
          handleNavigateFromChild={handleNavigateFromChild}
          dispatchCallback={updateLicenseFeature}
        />
      )}
    </Box>
  );
};

export default FeaturesListView;
