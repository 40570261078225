import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  HStack,
  CircularProgress,
  Text,
  Circle,
} from '@chakra-ui/react';
import { RightChervonIcon } from 'assets/icons/icons';
import StatusInfo from 'components/status/StatusInfo';
import OrganizationInfoSidebar from 'pages/organizations/components/organizations/OrganizationInfoPages/OrganizationInfoSidebar';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getDeviceStatus } from 'redux/devices/Actions/deviceActions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';

const EditDeveloperSettingsInfoPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const toggle = useAppSelector((state: RootState) => state.systemSettings.sidebarToggle);
  const singleUserData = useAppSelector((state: RootState) => state.user.singlePulseUserData);
  const editPartnerAccessApiData = useAppSelector((state: any) => state.clientAccess.editPartnerAccessApiDataValue);
  const deviceStatusData = useAppSelector((state: RootState) => state.device.statusData);
  const devicesGridView = useAppSelector((state: RootState) => state.device.devicesGridView);
  const routes = [
    {
      id: 1,
      pathname: 'info',
      name: 'information',
      hasAccess: true,
    },
    // {
    //   id: 2,
    //   pathname: `/users/${params.modelId}/info/${params.deviceId}/useractivities`,
    //   name: 'users_activity',
    //   disable:true
    // },
  ];

  useEffect(() => {
    dispatch(getDeviceStatus());
  }, []);

  const handleDeviceClick = () => {
    navigate(`/settings/partners`);
    // dispatch(getSelectedDevice(singleDeviceData?.catalogue?.internal_name));
  };

  return (
    <>
      <Box data-testid="org-info-container">
        <Box display="flex" h="90px" borderBottom="1px solid #3333331A">
          <div data-testid="org-info-breadcumb">
            <Breadcrumb spacing="8px" ml="40px" pt="35px" separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  color="#24B0FF"
                  position="absolute"
                  left="16px"
                  top="14px"
                  onClick={handleDeviceClick}
                  display="flex"
                  alignItems="center"
                >
                  <RightChervonIcon />

                  <Text ml="5px">
                    <FormattedMessage id="partner_access.message" />
                  </Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <HStack spacing={2} style={{ margin: '-20px 0 0 15px' }}>
              <Circle size="9px" mx="5px" mt="5px"></Circle>
              <Heading fontSize="36px" p="20px" fontWeight="400" fontFamily="Helvetica" style={{ paddingLeft: '0px' }}>
                {editPartnerAccessApiData?.name ? (
                  <Box display="flex" ml="-25px">
                    <StatusInfo
                      indicatorSize="9px"
                      indicatorColor={editPartnerAccessApiData?.status.color_code}
                      label={editPartnerAccessApiData.name}
                    />
                  </Box>
                ) : (
                  <CircularProgress size="30px" isIndeterminate />
                )}
              </Heading>
            </HStack>
          </div>
        </Box>
        <Box display="flex" h={`calc(100vh - 180px)`}>
          <Box borderRight="1px solid #3333331A">
            <OrganizationInfoSidebar routes={routes} />
          </Box>
          <Box w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 470px)' : 'calc(100vw - 340px)' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default EditDeveloperSettingsInfoPage;
