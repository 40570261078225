import { FormikErrors, FormikTouched } from 'formik';

export interface SsoToken {
  token: string;
}
export interface SSoUser {
  isSsoEnabled: boolean;
  isEmailExists: boolean;
  isSuperAdmin: boolean;
  emailId: string;
  errorMessage: string;
}
export interface Token {
  token: string;
}
export interface User {
  error_code: string;
  access_token?: string;
  token_type: string;
  user_id: string;
  org_code: string;
}
export interface OrganzationCard {
  id: number;
  text: string;
}
export interface UserCheckResponse {
  errorMessage: string;
  data: UserInfo;
  isSsoEnabledUser: boolean;
  userId: string;
}
export interface UserBody {
  username: string;
  password: string;
  org_code: string;
}
export interface UserInfo {
  auth_info: AuthInfo;
  auth_profiles: AuthProfile[];
  org_language_code: string;
  org_id: string;
  org_country_code: string;
  org_logo_url: string;
  service_env: string;
  service_info: ServiceInfo;
  org_name: string;
}

export interface AuthInfo {
  auth0_client_id_mobile: string;
  auth0_client_id_web: string;
  auth0_domain: string;
}

export interface AuthProfile {
  auth0_connection: string;
  auth0_org_id: string;
  auth_type: string;
}

export interface ServiceInfo {
  web_url: string;
  api_url: string;
}

export interface ToastTypes {
  description?: string;
  status: 'info' | 'warning' | 'success' | 'error' | 'loading';
}

export interface FormikErrorType {
  label: string;
  name: string;
  placeholder?: string;
  errors?: FormikErrors<{
    [field: string]: string | boolean;
  }>;
  touched?: FormikTouched<{
    [field: string]: string | boolean;
  }>;
  autofocus?: boolean;
  disabled?: boolean;
}

export interface SingleProps {
  showPassword: boolean;
}

export interface Email {
  email: string;
}

export interface Password {
  password: string;
}

export interface InitialFormValues {
  email: string;
  password: string;
}

export interface HandleLoginType {
  idToken?: string;
  isAuthenticated: boolean;
  isLoading: boolean;
}

export interface Auth0Config {
  domain: string;
  clientId: string;
  redirectURI: string;
  connection: string;
}

export enum LOCALES {
  EN = 'en',
  DE = 'de',
}
/*
 * Parsed from the version tag, same format used on front end and backend.
 * Not currently used by Pulse, but might be in future.
 * Example:
 * _gnexgen-web-ui_brelease-2.1.0_v2.1.0_cbb44915c_rIR1
 * Format is _g{repo}_b{branch_name}_v{version}_c{short_commit_hash}_r{release}
 */
export interface VersionInfo {
  repo: string;
  branch: string;
  productVersion: string;
  commit: string | undefined;
  releaseName: string;
}
export interface OrgInformation {
  id: number;
  created: string;
  customerOrgCode?: string;
  scannedPatientIdType: string;
  dicomDefaultPatientIdType: string;
  performingTagIdentifierType: string;
  workflowDefinition?: string;
}

export type UserActivityResponse = {
  message: 'created';
};
