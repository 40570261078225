import { createSlice } from '@reduxjs/toolkit';
import { PaginationTypes } from 'types/pagination-types';

interface ResFeature {
  id: number;
  enabled: boolean;
  name: string;
  code: string;
  group_name: string;
  controlled_by_pulse: boolean;
}

type Feature = {
  id: number;
  enabled: boolean;
  name: string;
  code: string;
  groupName: string;
  isUpdating: boolean;
};

type initialState = {
  featureList: Feature[];
  pagination: PaginationTypes | null;
  isLoading: boolean;
  featureId: number;
  isFeatureStatusFail: boolean;
  isStatusUpdated: boolean;
};
export const initialState: initialState = {
  featureList: [],
  pagination: null,
  isLoading: false,
  featureId: 0,
  isFeatureStatusFail: false,
  isStatusUpdated: false,
};

const orgFeatureSlice = createSlice({
  name: 'orgFeatures',
  initialState,
  reducers: {
    setFeaturesList: (state, { payload }) => {
      state.featureList = payload.data.map((feature: ResFeature) => {
        return {
          id: feature.id,
          enabled: feature.enabled,
          name: feature.name,
          code: feature.code,
          groupName: feature.group_name,
          isUpdating: false,
          controlledByPulse: feature.controlled_by_pulse,
        };
      });
      state.pagination = payload.pagination;
      state.isLoading = false;
    },
    fetchFeaturesLoading: (state) => {
      state.isLoading = true;
    },
    setFeatureUpdating: (state, { payload: { id } }) => {
      state.isStatusUpdated = true;
      state.featureId = id;
      state.featureList = state.featureList.map((feature: Feature) =>
        feature.id === id ? { ...feature, isUpdating: !feature.isUpdating } : feature,
      );
    },
    isUpdatedStatusFail: (state) => {
      state.isFeatureStatusFail = true;
      state.isStatusUpdated = false;
    },
    updateFeatureAvailablity: (state, { payload: { id } }) => {
      state.isStatusUpdated = false;
      state.featureList = state.featureList.map((feature: Feature) =>
        feature.id === id ? { ...feature, isUpdating: !feature.isUpdating, enabled: !feature.enabled } : feature,
      );
    },
  },
});

export const {
  setFeaturesList,
  fetchFeaturesLoading,
  updateFeatureAvailablity,
  setFeatureUpdating,
  isUpdatedStatusFail,
} = orgFeatureSlice.actions;
export default orgFeatureSlice.reducer;
