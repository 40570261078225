import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { RootState } from 'redux/Store/store';
import { fetchLicenseFeatures } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import FeaturesListView from './FeaturesListView';
import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import MobilePagination from 'components/pagination/mobile/MobilePagination';
import Loader from 'components/loader/Loader';
import Search from 'components/customSearch/Search';
import LandingScreen from 'components/landingPage/LandingSreen';

import { FormattedMessage } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { AddIcon } from 'assets/icons/icons';
import { handleFeaturesModal } from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import FeaturesAddModal from './FeaturesModal/FeaturesAddModal';
import { userAccessConst } from 'utils/constants/userAccessConst';

const FeaturesContainer = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const isLoading = useAppSelector((state: RootState) => state.softwareBundles.isFeaturesLoading);
  const featuresData = useAppSelector((state: RootState) => state.softwareBundles.featuresData);
  const primarblackAndWhiteColorMode = useColorModeValue('#24B0FF', '#10F8E4');
  const textColorMode = useColorModeValue('white', 'black');
  const fillColorMode = useColorModeValue('white', 'transparent');
  const strokeColorMode = useColorModeValue('white', 'none');
  const featuresList = featuresData?.data;
  const [searchText, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    dispatch(fetchLicenseFeatures());
  }, []);

  const handleMobilePageClick = (item: any) => {
    dispatch(fetchLicenseFeatures(item, searchText));
    setSelectedPage(item);
  };
  const handleDesktopPageClick = (data: any) => {
    dispatch(fetchLicenseFeatures(data.selected + 1, searchText));
    setSelectedPage(data.selected + 1);
  };

  const handleSearchValue = (value: string) => {
    dispatch(fetchLicenseFeatures(1, value));
    setSearchText(value);
    setSelectedPage(1);
  };

  const handleSearchClear = () => {
    dispatch(fetchLicenseFeatures(1, ''));
    setSearchText('');
    setSelectedPage(1);
  };

  const handleAddModal = () => {
    dispatch(handleFeaturesModal(true));
  };

  const showEmptyMessage = !isLoading && !searchText.length && !featuresData?.pagination?.count;

  return (
    <Box>
      {showEmptyMessage && (
        <Box data-testid="landing-screen" ml="15px" mt="50px">
          <LandingScreen
            handleClick={handleAddModal}
            title="License features"
            isFiltersApplied={false}
            onlyTitle={false}
            newCreation={true}
            pageName={'features_empty_page'}
          />
        </Box>
      )}
      {!showEmptyMessage && (
        <Flex alignItems="center" h="50px" m="15px 15px 0 0" justifyContent="space-between">
          <Flex alignItems="center">
            <Text
              mr={{ xs: '3' }}
              fontSize={{ xs: '22px', xl: '24px' }}
              fontWeight="medium"
              data-testid="org-title"
              color={colors.textColor}
            >
              <FormattedMessage id="add_features.message" />
            </Text>
            {userPermissions.bundles?.includes(userAccessConst.bundle.addBundle) && (
              <Icon
                w="24px"
                h="24px"
                color={textColorMode}
                fill={fillColorMode}
                onClick={handleAddModal}
                _hover={{
                  color: 'url("#BlueGradient")',
                  cursor: 'pointer',
                  stroke: 'white',
                  fill: 'url("#BlueGradient")',
                  '&:hover>svg>rect': { fill: 'white', stroke: 'none' },
                  '&:hover>svg>circle': { stroke: strokeColorMode },
                }}
              >
                <AddIcon stroke={primarblackAndWhiteColorMode} />
              </Icon>
            )}
          </Flex>
          {featuresData && (
            <Box>
              <Search
                data={{ searchText, isSearchExpand: false }}
                handleClose={() => {}}
                handleClear={handleSearchClear}
                handleExpandClick={() => {}}
                handleSearch={handleSearchValue}
              />
            </Box>
          )}
        </Flex>
      )}
      {isLoading ? <Loader isLoading={isLoading} /> : <FeaturesListView searchText={searchText} />}
      {featuresData?.pagination && featuresList && featuresList.length > 0 && (
        <Flex
          w="100%"
          pr="40px"
          h="100px"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          bottom="65px"
        >
          <TableInformation selectedPage={selectedPage} data={featuresList} pagination={featuresData.pagination} />
          <Box display={{ xs: 'none', xl: 'block' }}>
            <DesktopPaginate
              data={featuresList}
              handleDesktopPageClick={handleDesktopPageClick}
              theme={theme}
              pagination={featuresData.pagination}
              selectedPage={selectedPage}
            />
          </Box>
          <Box display={{ xs: 'block', xl: 'none' }}>
            <MobilePagination
              data={featuresList}
              selectedPage={selectedPage}
              numberOfPages={featuresData.pagination?.num_pages}
              handleMobilePageClick={handleMobilePageClick}
            />
          </Box>
        </Flex>
      )}
      <>
        <FeaturesAddModal />
      </>
    </Box>
  );
};

export default FeaturesContainer;
