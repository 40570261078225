/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import {
  AbsoluteCenter,
  Box,
  Button,
  CircularProgress,
  Icon,
  Input,
  Text,
  Wrap,
  WrapItem,
  Circle,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { customColor } from 'assets/styles/colors';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import { editDeviceInfoData, getSingleDevicesData } from 'redux/organiztionDevices/Actions/orgDeviceActions';
import { OrgName } from 'types/organization-types';
import { getDeviceStatuses } from 'redux/devices/Actions/deviceActions';
import {
  fetchOrganizationNameFail,
  fetchOrganizationNameStatus,
  getOrganizationInputNameValue,
} from 'redux/organizations/Reducers/organizationSlice';
import {
  fetchEditDeviceInfoApiFail,
  fetchOrderIdFail,
  fetchOrderIdStatus,
  getOrderIdInputValue,
  getReasonInputValue,
  handleOrgDeviceUnAssignModal,
} from 'redux/organiztionDevices/Reduces/orgDeviceSlice';
import { getOrganizationNameData } from 'redux/organizations/Actions/organizationActions';
import { InputLabel } from 'components/InputField';
import { specialCharacterValidation } from 'utils/validations/specialCharacterValidation';
import { CircleCheckIconOrg } from 'assets/icons/icons';
import { InputAutoComplete } from 'components/inputAutoComplete/InputAutoComplete';
import OrgInfoDeviceUnAssignModal from '../orgDevicesUnAssign/OrgDeviceUnAssignModal';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { getDeviceStatusInputValue } from 'redux/devices/Reducers/deviceSlice';
import { Error_Type_Enum } from '../../../../../../../../utils/enum';
import getOrgNameWithTooltip, { getOrgNameWithTooltipForDropDowns } from 'utils/getTextTooltip';
import { userAccessConst } from 'utils/constants/userAccessConst';
import StatusInfo from 'components/status/StatusInfo';

const SingleDeviceInfo = () => {
  const colors = customColor();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const { orgId, deviceId } = useParams();
  const [isEnabled, setButtonEnabled] = useState<boolean>(false);
  const singleDeviceData = useAppSelector((state: RootState) => state.organizationdevice.SingleDeviceGetData);
  const SingleDeviceDataLoading = useAppSelector(
    (state: RootState) => state.organizationdevice.SingleDeviceDataLoading,
  );
  const singleDeviceId = useAppSelector((state: RootState) => state.organizationdevice.SingleDeviceGetData?.id);
  const getDeviceStatusValue = useAppSelector((state: RootState) => state.device.deviceStatusInputValue);
  const orgInputNameValue = useAppSelector((state: RootState) => state.organization.organizationInputNameValue);
  const orderIdInputValue = useAppSelector((state: RootState) => state.organizationdevice.orderIdInputValue);
  const editDeviceInfoLoading = useAppSelector((state: RootState) => state.organizationdevice.editDeviceInfoLoading);
  const editDeviceInfoApiFail = useAppSelector((state: RootState) => state.organizationdevice.editDeviceInfoApiFail);
  const deviceStatusData = useAppSelector((state: RootState) => state.device.statusData);
  const orgNameStatus = useAppSelector((state: RootState) => state.organization.organizationHandleName);
  const orgNameErrorStatus = useAppSelector((state: RootState) => state.organization.organizationNameDataError);
  const orderIdStatus = useAppSelector((state: RootState) => state.organizationdevice.orderIdHandleStatus);
  const orderIdErrorStatus = useAppSelector((state: RootState) => state.organizationdevice.orderIdDataError);
  // const orgId = useAppSelector((state) => state.organizationdevice.SingleDeviceGetData?.org_id?.id);
  const orgIdSelected = useAppSelector((state) => state.device.orgNamePostValue);

  const orgNameSearchData = useAppSelector((state) => state.device.orgNameSearchData);
  const temp = orgNameSearchData.map((item: any) => ({ name: item.org_name, code: item.id }));
  const [deviceStatusValue, setDeviceStatusValue] = useState<any>(
    singleDeviceData && singleDeviceData?.device_status?.status,
  );
  const hasEditAccess =
    (orgId && userPermissions.organizations?.includes(userAccessConst.organization.updateDevice)) ||
    (!orgId && userPermissions.devices?.includes(userAccessConst.device.updateDevice));

  const hasUnAssignAccess =
    (orgId && userPermissions.organizations?.includes(userAccessConst.organization.unAssignDevice)) ||
    (!orgId && userPermissions.devices?.includes(userAccessConst.device.unAssignDevice));

  useEffect(() => {
    dispatch(getSingleDevicesData(deviceId));
    dispatch(getDeviceStatuses());
  }, [deviceId]);

  useEffect(() => {
    setDeviceStatusValue(singleDeviceData && singleDeviceData?.device_status?.status);
  }, [singleDeviceData]);

  const PostOrgName: OrgName = {
    org_name: orgInputNameValue,
    status: undefined,
    success: false,
    message: undefined,
  };
  const orgDeviceUnAssignModal = () => {
    dispatch(handleOrgDeviceUnAssignModal(true));
  };

  useEffect(() => {
    orgNameStatus;
    orgNameErrorStatus;
  }, [orgInputNameValue, orgNameErrorStatus, orgNameStatus]);

  useEffect(() => {
    orderIdStatus;
    orderIdErrorStatus;
  }, [orderIdInputValue, orderIdErrorStatus, orderIdStatus]);

  useEffect(() => {
    orgInputNameValue && setButtonEnabled(true);
  }, [orgInputNameValue]);

  const handleInputName = (e: any) => {
    setButtonEnabled(true);
    if (e.target.name == 'orgInputNameValue') {
      dispatch(getOrganizationInputNameValue(e.target.value));
    } else if (e.target.name == 'orderIdInputValue') {
      dispatch(getOrderIdInputValue(e.target.value));
    } else {
      dispatch(getReasonInputValue(e.target.value));
    }
  };
  const handleStatusChange = (state: any) => {
    setButtonEnabled(true);
    setDeviceStatusValue(state.status_name);
    dispatch(getDeviceStatusInputValue(state.status_id));
  };

  const handleOrgNameChange = () => {
    dispatch(fetchOrganizationNameFail(false));
    dispatch(fetchOrganizationNameStatus(false));
    if (orgInputNameValue.length > 0 && orgInputNameValue.length < 4) {
      dispatch(fetchOrganizationNameStatus(true));
      dispatch(fetchOrganizationNameFail(false));
    } else if (orgInputNameValue.length > 3) {
      dispatch(getOrganizationNameData(PostOrgName));
      dispatch(fetchOrganizationNameStatus(false));
      dispatch(fetchOrganizationNameFail(false));
    } else if (orgInputNameValue.length == 0) {
      dispatch(fetchOrganizationNameFail(false));
      dispatch(fetchOrganizationNameStatus(false));
    } else {
      dispatch(fetchOrganizationNameFail(false));
      dispatch(fetchOrganizationNameStatus(false));
    }
  };

  const handleOrderIdChange = () => {
    dispatch(fetchOrderIdFail(false));
    dispatch(fetchOrderIdStatus(false));
    if (orderIdInputValue.length > 0 && orderIdInputValue.length < 4) {
      dispatch(fetchOrderIdFail(false));
      dispatch(fetchOrderIdStatus(true));
    } else if (orderIdInputValue.length > 3) {
      // dispatch(getOrderIdData(PostOrderId));
      dispatch(fetchOrderIdFail(false));
      dispatch(fetchOrderIdStatus(false));
    } else if (orderIdInputValue.length == 0) {
      dispatch(fetchOrderIdFail(false));
      dispatch(fetchOrderIdStatus(false));
    } else {
      dispatch(fetchOrderIdFail(false));
      dispatch(fetchOrderIdStatus(false));
    }
  };

  const postDevInfoData = {
    created_at: singleDeviceData?.created_at,
    updated_at: singleDeviceData?.updated_at,
    catalogue: singleDeviceData?.catalogue?.id,
    serial_number: singleDeviceData?.serial_number,
    device_status: getDeviceStatusValue ? getDeviceStatusValue : singleDeviceData?.device_status?.id,
    order_id: orderIdInputValue || orderIdInputValue?.length == 0 ? orderIdInputValue : singleDeviceData?.order_id,
    org_id: orgIdSelected ? orgIdSelected : orgId,
    udid: singleDeviceData?.udid,
    manufacture_date: singleDeviceData?.manufacture_date,
    last_used_by: singleDeviceData?.last_used_by,
    last_used_on: singleDeviceData?.last_used_on,
  };

  useEffect(() => {
    if (editDeviceInfoApiFail === 'SUCCESS') {
      setTimeout(() => {
        dispatch(fetchEditDeviceInfoApiFail(''));
        dispatch(getSingleDevicesData(deviceId));
        setButtonEnabled(false);
      }, 1000);
    }
  }, [editDeviceInfoApiFail]);
  useEffect(() => {
    setButtonEnabled(false);
  }, []);

  const handleEditDevice = () => {
    dispatch(editDeviceInfoData(postDevInfoData, singleDeviceId));
  };

  return (
    <Box
      height="calc(100vh - 180px)"
      display="block"
      paddingBottom="50px"
      overflowX="hidden"
      overflowY="scroll"
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {singleDeviceData && !SingleDeviceDataLoading ? (
        <Box display="block">
          <Box w="100%" display="block" pl="18px">
            <Box mt="20px" minH="90px">
              <Wrap display="flex" spacing="40px">
                <WrapItem>
                  <Box>
                    <Text fontWeight="700" fontSize="24px" minWidth={305}>
                      Information
                    </Text>
                  </Box>
                </WrapItem>
                <WrapItem paddingTop="14px" minWidth={305} flexDirection="row-reverse">
                  <Wrap display="flex">
                    <WrapItem>
                      {hasEditAccess && (
                        <Box ml="5px">
                          <Button
                            isLoading={editDeviceInfoLoading}
                            width="82px"
                            h="24px"
                            border={isEnabled ? '1px solid #333333' : '1px solid #24B0FF'}
                            borderRadius="15px"
                            borderColor={isEnabled ? '#24B0FF' : '#333333'}
                            color={isEnabled ? '#24B0FF' : '#333333'}
                            loadingText="Save"
                            colorScheme="teal"
                            variant="outline"
                            type="button"
                            spinnerPlacement="end"
                            onClick={handleEditDevice}
                            isDisabled={!isEnabled}
                            fontSize="14px"
                            fontWeight="500"
                          >
                            <Box display="flex" alignItems="center">
                              <Text fontSize="14px" fontWeight="500" color={isEnabled ? '#24B0FF' : '#333333'}>
                                Save
                              </Text>

                              {editDeviceInfoApiFail === Error_Type_Enum.Success && (
                                <Icon w="20px" pl="4px" h="20px">
                                  <CircleCheckIconOrg />
                                </Icon>
                              )}
                            </Box>
                          </Button>
                        </Box>
                      )}
                    </WrapItem>
                  </Wrap>
                </WrapItem>
              </Wrap>
            </Box>
            <Box minH="90px" mt="-20px">
              <Wrap display="flex" spacingX="40px">
                <WrapItem>
                  <Box>
                    <InputLabel value={singleDeviceData?.catalogue?.device_name} name="device_type_internal" />
                    <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                      {getOrgNameWithTooltip(singleDeviceData?.catalogue?.device_name)} -{' '}
                      {getOrgNameWithTooltipForDropDowns(singleDeviceData?.catalogue?.internal_name)}
                    </Text>
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box>
                    <InputLabel value={singleDeviceData?.catalogue?.catalogue_number} name="catalogue" />
                    <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                      {getOrgNameWithTooltipForDropDowns(singleDeviceData?.catalogue?.catalogue_number)}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
            <Box minH="90px">
              <Wrap display="flex" spacing="35px">
                <WrapItem>
                  <Box>
                    <InputLabel value={singleDeviceData?.serial_number} name="serial#" />
                    <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305} className="truncatee">
                      {singleDeviceData.serial_number}
                    </Text>
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box>
                    <InputLabel value={deviceStatusValue} name="status" />
                    {hasEditAccess ? (
                      <Menu>
                        {({ isOpen }) => (
                          <>
                            <MenuButton
                              isActive={isOpen}
                              as={Button}
                              minWidth={305}
                              width={{ xs: '95vw', smd: '95vw', md: '305px' }}
                              borderBottom="1px solid"
                              borderRadius="0px"
                              _focusVisible={{ borderBottomColor: colors.primaryColor }}
                              borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                              variant="flushed"
                              data-testid="info-license-info"
                              rightIcon={
                                isOpen ? (
                                  <Icon mr="-10px" as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                                ) : (
                                  <Icon mr="-10px" as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                                )
                              }
                            >
                              {deviceStatusValue ? (
                                <Box
                                  float="left"
                                  marginLeft="-10px"
                                  fontSize="14px"
                                  fontWeight="400"
                                  color={colors.subTextColor}
                                >
                                  {deviceStatusData && (
                                    <Box display="flex" alignItems="center" fontSize="14px" color={colors.textColor}>
                                      <StatusInfo
                                        indicatorColor={
                                          deviceStatusData.find((item: any) => item.status_name == deviceStatusValue)
                                            ?.color_code
                                        }
                                        indicatorSize="6px"
                                        label={
                                          deviceStatusData.find((item: any) => item.status_name == deviceStatusValue)
                                            ?.status_name
                                        }
                                      />
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                                  <FormattedMessage id="status.message" />
                                </Text>
                              )}
                            </MenuButton>

                            <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }} pl="5px">
                              {deviceStatusData &&
                                deviceStatusData.map((state: any, index: number) => (
                                  <MenuItem
                                    ml="-7px"
                                    id={state.id}
                                    key={index}
                                    value={state.id}
                                    name={state.status_name}
                                    backgroundColor="transparent"
                                    _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                                    onClick={() => handleStatusChange(state)}
                                  >
                                    <Box fontSize="14px" fontWeight="400" color={colors.subTextColor}>
                                      {deviceStatusData && (
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          fontSize="14px"
                                          color={colors.textColor}
                                        >
                                          <StatusInfo
                                            indicatorColor={state.color_code}
                                            indicatorSize="6px"
                                            label={state.status_name}
                                          />
                                        </Box>
                                      )}
                                    </Box>
                                  </MenuItem>
                                ))}
                            </MenuList>
                          </>
                        )}
                      </Menu>
                    ) : (
                      <Box
                        fontWeight="400"
                        fontSize="14px"
                        marginTop={2}
                        minWidth={305}
                        className="truncatee"
                        color={colors.subTextColor}
                      >
                        {deviceStatusData && (
                          <Box display="flex" alignItems="center" color={colors.textColor}>
                            <StatusInfo
                              indicatorColor={
                                deviceStatusData.find((item: any) => item.status_name == deviceStatusValue)?.color_code
                              }
                              indicatorSize="6px"
                              label={
                                deviceStatusData.find((item: any) => item.status_name == deviceStatusValue)?.status_name
                              }
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
            <Box minH="90px">
              <Wrap display="flex" spacing="43px">
                <WrapItem>
                  <Box>
                    <InputLabel value={singleDeviceData.manufacture_date} name="manufacture_date" />
                    <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                      {moment(singleDeviceData?.manufacture_date?.slice(0, 10)).format('ll')}
                    </Text>
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box>
                    <InputLabel value={singleDeviceData?.catalogue?.catalogue_number} name="device_identifier" />
                    <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                      {singleDeviceData?.udid}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
            <Box minH="90px">
              <Wrap display="flex" spacing="35px">
                <WrapItem>
                  <Box minH="90px">
                    <InputLabel value="organizations_name" name="organizations_name_optional" />
                    {singleDeviceData?.org_id?.org_name?.length > 0 ? (
                      <Text
                        fontWeight="400"
                        fontSize="14px"
                        className="truncatee"
                        textTransform="capitalize"
                        marginTop={2}
                        minWidth={305}
                      >
                        {singleDeviceData?.org_id?.org_name}
                      </Text>
                    ) : (
                      <Box>
                        <InputAutoComplete
                          inputStyle={{
                            position: 'fixed',
                            backgroundColor: 'red',
                            borderBlockColor: 'red',
                            textTransform: 'capitalize',
                          }}
                          textSize="13px"
                          optionsStyle={{ zIndex: '999', backgroundColor: 'white' }}
                          data={temp}
                          orgName={singleDeviceData?.org_id?.org_name}
                        />
                      </Box>
                    )}
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box minH="90px">
                    <InputLabel value={singleDeviceData.order_id || orderIdInputValue} name="order_id" />
                    {hasEditAccess ? (
                      <>
                        <Input
                          mt="2px"
                          minLength={4}
                          color={colors.subTextColor}
                          className="form-input"
                          defaultValue={singleDeviceData.order_id}
                          key={singleDeviceData.order_id}
                          fontSize="13px"
                          name="orderIdInputValue"
                          width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                          onChange={handleInputName}
                          placeholder={intl.formatMessage({ id: 'order_id.message' })}
                          borderColor="#E0E0E0"
                          _placeholder={{ color: 'rgba(51, 51, 51, 0.6)', fontsize: '13px' }}
                          onBlur={handleOrderIdChange}
                          focusBorderColor={colors.primaryColor}
                          borderBottomColor={colors.inputBorderColor}
                          borderWidth="0 0 1px 0"
                          type="text"
                          onKeyPress={specialCharacterValidation}
                          variant="flushed"
                          required
                          maxLength={30}
                          data-testid="info-orginfo-name"
                        />

                        <Box fontSize="12px" color="#FB5654">
                          {orderIdStatus ? (
                            <FormattedMessage id="org_name_error.message" />
                          ) : (
                            <>
                              {orderIdErrorStatus &&
                              singleDeviceData &&
                              singleDeviceData.order_id !== orderIdInputValue ? (
                                <FormattedMessage id="org_name_exists.message" />
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </Box>
                      </>
                    ) : (
                      <Text fontWeight="400" fontSize="14px" className="truncatee" marginTop={2} minWidth={305}>
                        {singleDeviceData?.order_id}
                      </Text>
                    )}
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
            <Box mt="20px" minH="90px">
              {singleDeviceData?.org_id?.org_name?.length > 0 && hasUnAssignAccess ? (
                <Box w="757px" h="98px" mt="20px">
                  <Box>
                    <Text fontSize="18px" fontWeight="700">
                      Additional Action
                    </Text>
                  </Box>
                  <Text fontSize="12px" color={colors.tableHeadColor} mt="30px" fontWeight="700">
                    {' '}
                    Un-assign Organization
                  </Text>
                  <Box display="flex" mt="10px">
                    <Box width="71%">
                      <Text fontSize="14px" color={colors.copyRightColor}>
                        This device will be unassigned from{' '}
                        <span style={{ textTransform: 'capitalize' }}>{singleDeviceData?.org_id?.org_name}</span>{' '}
                        organization
                      </Text>
                    </Box>

                    <Button
                      variant="outline"
                      borderRadius="15px"
                      height="27px"
                      fontWeight="700"
                      fontSize="12px"
                      width="110px"
                      mt="-25px"
                      _hover={{
                        background: '#24B0FF',
                        color: 'white',
                      }}
                      borderColor={'#24B0FF'}
                      color={'#24B0FF'}
                      onClick={orgDeviceUnAssignModal}
                    >
                      Un-Assign
                    </Button>
                    <OrgInfoDeviceUnAssignModal />
                  </Box>
                </Box>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box position="relative" h="100%">
          <AbsoluteCenter p="4" axis="both">
            <CircularProgress isIndeterminate size="50px" />
          </AbsoluteCenter>
        </Box>
      )}
    </Box>
  );
};
export default SingleDeviceInfo;
