/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled eslint rule for any type to support auth0 loginWithRedirect hook. */
/* Since auth0 hooks can only be called inside of the body of a function component */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Dispatch } from 'react';
import ApiUtil, { makeHeaders } from '../api';

import {
  fetchSingleSignOnCheckLoading,
  fetchSingleSignOnCheckSuccess,
  fetchSingleSignOnCheckFail,
  fetchSsoUserLoading,
  fetchSsoUserSuccess,
  fetchSsoUserFail,
  fetchUserLoading,
  fetchUserSuccess,
  fetchUserFail,
  createUserSessionLoading,
  createUserSessionFail,
  createUserSessionSuccess,
  fetchOwnerOrganizations,
} from './login.slice';

interface IGetAllAssets {
  type: boolean | string;
}

const handleAuth = ({ email, loginWithRedirect, selectedOrganization, dispatch, userId, errorMessage }: any) => {
  const isSsoEnabledUser = selectedOrganization?.auth_profiles[0].auth_type.includes('AUTH0');
  dispatch(
    fetchSingleSignOnCheckSuccess({
      data: selectedOrganization,
      userId: userId,
      isSsoEnabledUser: isSsoEnabledUser,
      errorMessage: errorMessage,
    }),
  );
  if (isSsoEnabledUser) {
    const auth0Domain = selectedOrganization?.auth_info.auth0_domain;
    const auth0TenantDomain = selectedOrganization?.auth_info.auth0_tenant_domain;
    const auth0ClientId = selectedOrganization?.auth_info.auth0_client_id_web;
    const auth0Connection = selectedOrganization?.auth_profiles[0].auth0_connection;

    // storing it on localstorage to re-use on page refresh.
    localStorage.setItem('auth0_domain', auth0Domain);
    localStorage.setItem('auth0_tenant_domain', auth0TenantDomain);
    localStorage.setItem('auth0_clientId', auth0ClientId);
    localStorage.setItem('auth0_connection', auth0Connection);

    if (loginWithRedirect) {
      loginWithRedirect({
        clientId: auth0ClientId,
        domain: auth0Domain,
        authorizationParams: {
          login_hint: email,
          connection: auth0Connection,
          redirect_uri: window.location.origin,
        },
      });
    } else {
      dispatch(
        resetPassword({
          email: localStorage.getItem('exo_user_email') || '',
        }),
      );

      return;
    }
  }
};

const handleUserInfo = async (
  email: string,
  baseUrl: string,
  dispatch: Dispatch<any>,
  loginWithRedirect?: (params: any) => void,
  selectedOrg?: any,
) => {
  if (selectedOrg && Object.keys(selectedOrg).length > 1) {
    const userId = localStorage.getItem('exo_user_email');
    const errorMessage = '';
    const selectedOrganization = selectedOrg;

    handleAuth({ email, loginWithRedirect, selectedOrganization, dispatch, userId, errorMessage });

    return;
  }

  dispatch(fetchSingleSignOnCheckLoading());
  try {
    const res = await axios.get(
      `${baseUrl}/userinfo?user_id=${encodeURIComponent(email)}&service_name=${
        process.env.NX_SERVICE_NAME
      }&service_env=${process.env.NX_SERVICE_ENV}`,
    );
    const orgs = res.data.orgs || [];

    if (res?.status === 500) {
      dispatch(fetchSingleSignOnCheckFail('internal_server_error_contact_admin.message'));
      return;
    }

    dispatch(fetchOwnerOrganizations(orgs));

    const selectedOrganization = orgs[0];
    if (res && res.status === 200) {
      if (orgs.length > 0) {
        // NOTE: Temporarily disabled until server side response is updated to contain status and license_expired fields
        // const statusInactive = orgs[0].status !== 'active';
        // const licenseExpired = orgs[0].license_expired;
        // if (statusInactive || licenseExpired) {
        //   dispatch(fetchSingleSignOnCheckFail('account_inactive_or_expired.message'));
        //   return;
        // }
        const userId = res.data.user_id || email;
        localStorage.setItem('exo_user_email', userId);
        const errorMessage = res.data.error_code;

        if (
          res.data.orgs[0].service_name === 'exo-pulse' ||
          (res.data.orgs[0].service_name === 'exo-works' && res.data.orgs.length === 1)
        ) {
          handleAuth({ email, loginWithRedirect, selectedOrganization, dispatch, userId, errorMessage });
        }
      } else {
        dispatch(fetchSingleSignOnCheckFail('account_not_found_contact_admin.message'));
        return;
      }
    }
  } catch (error) {
    dispatch(fetchSingleSignOnCheckFail('internal_server_error_contact_admin.message'));
  }
};

export const getSingleSignInUser =
  ({
    email,
    baseUrl,
    loginWithRedirect,
    selectedOrg,
  }: {
    email: string;
    baseUrl: string;
    loginWithRedirect: any;
    selectedOrg?: any;
  }) =>
  async (dispatch: Dispatch<IGetAllAssets>) => {
    await handleUserInfo(email, baseUrl, dispatch, loginWithRedirect, selectedOrg);
  };

export const getUserInfo =
  ({ email, baseUrl }: { email: string; baseUrl: string }) =>
  async (dispatch: Dispatch<any>) => {
    await handleUserInfo(email, baseUrl, dispatch);
  };

export const fetchSsoUser = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchSsoUserLoading());
  try {
    const res = await ApiUtil.post('generateToken', {});
    if (res && res.status === 200) {
      dispatch(fetchSsoUserSuccess());
    }
  } catch (error) {
    dispatch(fetchSsoUserFail());
  }
};

export const createUserSession = (baseUrl: string) => async (dispatch: Dispatch<IGetAllAssets>) => {
  try {
    const headers = makeHeaders();
    dispatch(createUserSessionLoading(true));
    const res = await axios.post(`${baseUrl}/create-session`, {}, { headers: headers });
    if (res) {
      if (res.status === 200) {
        dispatch(createUserSessionSuccess(true));
      } else {
        dispatch(createUserSessionFail(res.data?.error_code));
      }
    }
  } catch (error) {
    dispatch(createUserSessionFail(`Error creating user session: ${error}`));
  }
};

export type ResetUserPasswordRequest = {
  email: string;
};

export const resetPassword = createAsyncThunk('login/resetPassword', async ({ email }: ResetUserPasswordRequest) => {
  const authDomain = localStorage.getItem('auth0_domain');
  const auth0ClientId = localStorage.getItem('auth0_clientId');
  const auth0Connection = localStorage.getItem('auth0_connection');
  const res = await axios.post(`https://${authDomain}/dbconnections/change_password`, {
    email,
    client_id: auth0ClientId,
    connection: auth0Connection,
  });
  return res.data;
});

export const fetchUser =
  ({
    user,
    baseUrl,
  }: {
    user: { email: string; password: string; apiEndPoint: string; org_code: string };
    baseUrl?: string;
  }) =>
  async (dispatch: Dispatch<IGetAllAssets>) => {
    dispatch(fetchUserLoading());

    const data = JSON.stringify({
      org_code: user.org_code,
      user_id: user.email,
      password: user.password,
    });
    try {
      const res = await axios.post(`${baseUrl}/login/`, data);
      if (res && res.status === 200 && !res.data.error_code) {
        dispatch(fetchUserSuccess(res.data));
      } else {
        throw new Error(res.data.error_code);
      }
    } catch (error) {
      dispatch(fetchUserFail(true));
    }
  };
