/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  HStack,
  Text,
  Circle,
  Tooltip,
  FormLabel,
} from '@chakra-ui/react';
import { RightChervonIcon } from 'assets/icons/icons';
import OrganizationInfoSidebar from 'pages/organizations/components/organizations/OrganizationInfoPages/OrganizationInfoSidebar';
import { FormattedMessage } from 'react-intl';
import { NavLink as RouteLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { getDeviceStatus } from 'redux/devices/Actions/deviceActions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getOrganizationDevices, getSingleDevicesData } from 'redux/organiztionDevices/Actions/orgDeviceActions';
import {
  getOrganisationInfoUsersData,
  getOrgUsersStatus,
  getSingleUserData,
} from 'redux/orgUser/Actions/orguserActions';
import { RootState } from 'redux/Store/store';
import { customColor } from '../../../../libs/shared-ui/src/lib/assets/styles/colors';
import {
  // getOrganizationDuration,
  getSingleOrganizationsData,
  getStatus,
} from '../redux/organizations/Actions/organizationActions';
import { getOrgDeviceRoutes, getOrgRoutes, getOrgUserRoutes } from 'routes/orgInternalRoutes';
import { getOrganizationLicenses } from 'redux/organiztionLicenes/Actions/orgLicenseActions';
import { getOrgNameWithTooltipForDropDowns } from 'utils/getTextTooltip';

const SubLayout = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const params = useParams();
  const navigate = useNavigate();
  const toggle = useAppSelector((state: RootState) => state.systemSettings.sidebarToggle);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const singleOrganizationData = useAppSelector((state: RootState) => state.organization.SingleOrganizationsGetData);
  const orgStatusData = useAppSelector((state: RootState) => state.organization.statusData);
  const singleUser = useAppSelector((state: RootState) => state.orgUser.SingleUsersGetData);
  const userStatusData = useAppSelector((state: RootState) => state.orgUser.orgUsersStatus);
  const singleDeviceData = useAppSelector((state: RootState) => state.organizationdevice.SingleDeviceGetData);
  useEffect(() => {
    if (params.userId) {
      dispatch(getSingleUserData(params.orgId, params.userId));
    } else if (params.deviceId) {
      dispatch(getSingleDevicesData(params.deviceId));
    }
  }, [params]);
  useEffect(() => {
    dispatch(getSingleOrganizationsData(params.orgId));
  }, []);
  useEffect(() => {
    if (singleOrganizationData?.status == 2) {
      // dispatch(getOrganizationDuration(params.orgId));
    }
  }, [singleOrganizationData]);
  let routes: any;
  if (params.userId) {
    routes = getOrgUserRoutes(userPermissions.organizations);
  } else if (params.deviceId) {
    routes = getOrgDeviceRoutes(userPermissions.organizations);
  } else {
    routes = getOrgRoutes(userPermissions.organizations);
  }
  const handleClick = () => {
    if (params.userId) {
      navigate(`users`);
    } else if (params.deviceId) {
      navigate(`devices`);
    } else navigate(`/customers/?tab=organizations`);
  };
  const handleName = () => {
    let name = '';
    if (params.userId) {
      if (singleUser) {
        name = singleUser?.first_name + ' ' + singleUser?.last_name;
      } else {
        name = '';
      }
    } else if (params.deviceId) {
      name = singleDeviceData ? singleDeviceData.serial_number : '';
    } else {
      name = singleOrganizationData ? singleOrganizationData?.org_name : '';
    }
    const truncatedName = name.length > 30 ? `${name.slice(0, 20)}...` : name;
    if (name.length > 30) {
      return (
        <Tooltip label={name} mt="-8px" fontSize="15px" data-testid="tooltip-orgName">
          {truncatedName}
        </Tooltip>
      );
    }
    return truncatedName;
  };
  const handleStatusColor = () => {
    let status;
    if (params.userId) {
      if (singleUser) {
        status = userStatusData.find((item: any) => item.id == singleUser?.status)?.color_code;
      } else {
        status = '';
      }
    } else if (params.deviceId) {
      status = singleDeviceData?.device_status?.color_code;
    } else {
      status = orgStatusData.find((item: any) => item.status_id == singleOrganizationData?.status)?.color_code;
    }
    return status;
  };
  const handleTitle = () => {
    return params.deviceId || params.userId ? singleOrganizationData?.org_name : 'Organizations';
  };
  useEffect(() => {
    dispatch(getStatus());
    dispatch(getDeviceStatus());
    dispatch(getOrganizationLicenses(params.orgId, ''));
    dispatch(getOrganizationDevices(params.orgId, ''));
    dispatch(getOrganisationInfoUsersData(params.orgId, ''));
    dispatch(getOrgUsersStatus());
  }, []);
  return (
    <>
      <Box data-testid="org-info-container">
        <Box display="flex" h="90px" justifyContent="space-between" borderBottom="1px solid #3333331A">
          <Box data-testid="org-info-breadcumb">
            <Breadcrumb spacing="8px" ml="40px" pt="35px" separator={<ChevronRightIcon color="gray.500" />}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  color="#24B0FF"
                  position="absolute"
                  left="18px"
                  top="14px"
                  onClick={handleClick}
                  display="flex"
                  fontSize="14px"
                  alignItems="center"
                  textTransform="capitalize"
                >
                  <RightChervonIcon />
                  <Text ml="3px">{handleTitle()}</Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <HStack spacing={2} style={{ margin: '-20px 0 0 15px' }}>
              <Circle size="9px" bg={handleStatusColor()} mx="5px" mt="5px"></Circle>
              <Heading
                fontSize="36px"
                minW="200px"
                p="20px"
                fontWeight="400"
                fontFamily="Helvetica"
                style={{ paddingLeft: '0px' }}
                textTransform="capitalize"
              >
                {handleName()}
              </Heading>
              <Box>
                <Box display="flex" alignItems="flex-start">
                  <FormLabel
                    color={colors.textColor}
                    opacity="0.6"
                    fontSize={{ xs: '10px', lg: '10px', xl: '10px' }}
                    mb="5px"
                    data-testid="container-label"
                    fontWeight="300"
                    pb="0"
                  >
                    <FormattedMessage id="license_bundle_id.message" />
                  </FormLabel>
                </Box>
                <Box fontWeight="700" fontSize="13px" fontFamily="helvetica">
                  {singleOrganizationData?.bundle_info.catalog_number ? (
                    <Text>
                      {singleOrganizationData?.bundle_info.name} {'  '}#
                      {singleOrganizationData?.bundle_info.catalog_number}
                    </Text>
                  ) : (
                    '-'
                  )}
                </Box>
              </Box>
            </HStack>
          </Box>
        </Box>
        <Box display="flex" h={`calc(100vh - 180px)`}>
          <Box borderRight="1px solid #3333331A">
            <OrganizationInfoSidebar routes={routes} />
          </Box>
          <Box w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 430px)' : 'calc(100vw - 300px)' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default SubLayout;
