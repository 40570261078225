import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  Wrap,
  Heading,
  Box,
  Text,
  WrapItem,
  AbsoluteCenter,
  CircularProgress,
  Circle,
} from '@chakra-ui/react';
import { RightChervonIcon } from 'assets/icons/icons';
import { InputLabel } from 'components/InputField';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchLicenseFeature } from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import { Bundle } from 'types/feature';
import { customColor } from 'assets/styles/colors';

const FeatureInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const colors = customColor();
  const { featureId } = useParams();
  const isLoading = useAppSelector((state) => state.softwareBundles.isFeatureLoading);
  const featureInfo = useAppSelector((state) => state.softwareBundles.featureData);
  useEffect(() => {
    dispatch(fetchLicenseFeature(featureId));
  }, []);

  const navigateToFeatures = () => {
    navigate('/licenses?tab=features');
  };

  const renderBundleAddonLabel = (label: string, index = 1) => {
    return (
      <Box
        key={index}
        border="1px solid"
        borderColor={colors.primaryColor}
        display="inline-block"
        padding="10px 20px"
        borderRadius="20px"
        color={colors.primaryColor}
        margin="10px 25px 10px 0"
        fontSize="10px"
      >
        <Text>{label}</Text>
      </Box>
    );
  };

  return (
    <Box>
      <Box display="flex" h="100px" justifyContent="space-between" borderBottom="1px solid #3333331A">
        <Box data-testid="feature-info-breadcumb" pl="15px">
          <Breadcrumb spacing="8px" pt="15px" separator={<ChevronRightIcon color="gray.500" />}>
            <BreadcrumbItem minH="20px">
              <BreadcrumbLink
                color={colors.primaryColor}
                position="absolute"
                onClick={navigateToFeatures}
                display="flex"
                fontSize="14px"
                alignItems="center"
                textTransform="capitalize"
              >
                <RightChervonIcon />
                <Text ml="5px">Features</Text>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          {!isLoading && (
            <HStack gap="0px" minH="40px" mt="5px">
              <Circle size="9px" bg={'#1FD002'}></Circle>
              <Heading
                fontSize="36px"
                minW="200px"
                ml="7px"
                fontWeight="400"
                fontFamily="Helvetica"
                style={{ paddingLeft: '0px' }}
                textTransform="capitalize"
              >
                {featureInfo?.name}
              </Heading>
            </HStack>
          )}
        </Box>
      </Box>
      {!isLoading ? (
        <Box pl="30px" pb="30px" height="calc(100vh - 180px)" overflowY="auto">
          <Box mt="20px">
            <Text fontWeight="700" fontSize="24px">
              Information
            </Text>
            <Box mt="25px">
              <Wrap display="flex" spacing="40px" minH="70px">
                <WrapItem>
                  <Box minH="70px">
                    <InputLabel value="-" name="feature_name" />
                    <Text fontWeight="400" fontSize="13px" marginTop={2} minWidth={305} textTransform="capitalize">
                      {featureInfo?.name}
                    </Text>
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box minH="70px">
                    <InputLabel value="-" name="available" />
                    <Text fontWeight="400" fontSize="13px" marginTop={2} minWidth={305} textTransform="capitalize">
                      {featureInfo?.available ? 'Yes' : 'No'}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
              <Wrap display="flex" spacing="40px" minH="70px">
                <WrapItem>
                  <Box>
                    <InputLabel value="-" name="feature_group" />
                    <Text fontWeight="400" fontSize="13px" marginTop={2} minWidth={305}>
                      {featureInfo?.groupName || '-'}
                    </Text>
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box>
                    <InputLabel value="-" name="feature_code" />
                    <Text fontWeight="400" fontSize="13px" marginTop={2} minWidth={305}>
                      {featureInfo?.code}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
              <Wrap display="flex" spacing="40px" minH="70px">
                <WrapItem>
                  <Box>
                    <InputLabel value="-" name="notes" />
                    <Text fontWeight="400" fontSize="13px" marginTop={2} minWidth={305}>
                      {featureInfo?.description || '-'}
                    </Text>
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
          </Box>

          <Box mt="10px">
            <Text fontWeight="700" fontSize="18px" pb="10px">
              Linked to {featureInfo?.bundles.length} License{featureInfo && featureInfo?.bundles.length > 1 ? 's' : ''}
            </Text>
            {featureInfo?.bundles.map((bundle: Bundle, index: number) => {
              return renderBundleAddonLabel(bundle.name, index);
            })}
          </Box>
          {featureInfo?.featureAddon && (
            <Box mt="10px">
              <Text fontWeight="700" fontSize="18px" pb="10px">
                Linked to 1 Add-on
              </Text>
              {renderBundleAddonLabel(featureInfo?.featureAddon.name)}
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <AbsoluteCenter p="4" axis="both">
            <CircularProgress isIndeterminate size="50px" />
          </AbsoluteCenter>
        </Box>
      )}
    </Box>
  );
};

export default FeatureInfo;
