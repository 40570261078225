import { useEffect } from 'react';
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import getDashboard from 'redux/dashbaord/Actions/dashboardActions';
import { userAccessConst } from 'utils/constants/userAccessConst';
import PieChartComponent from './PieChartComponent';
import { User_Roles } from 'utils/enum';

interface IChartDataItem {
  name: string;
  value: number;
  fill: string;
}

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const toggle = useAppSelector((state) => state.systemSettings.sidebarToggle);
  const { customers, organizations, devices, users, userRole } = useAppSelector((state) => state.user.userPermissions);
  const { dashboardResponse } = useAppSelector((state: any) => state.dashboard);
  const isMarketingUserRole = userRole == User_Roles.Marketing;
  const charts = [
    {
      isVisible: isMarketingUserRole || customers?.includes(userAccessConst.customers.listCustomers),
      dataKey: 'value',
      totalDataKey: 'total_customers',
      totalMessageId: 'customers.message',
      dataMessageId: 'customers',
      navigatePath: !isMarketingUserRole && '/customers/?tab=customers',
      nameKey: 'status__status',
      valueKey: 'count',
      fillKey: 'color_code',
    },
    {
      isVisible: isMarketingUserRole || organizations?.includes(userAccessConst.organization.listOrg),
      dataKey: 'value',
      totalDataKey: 'total_organizations',
      totalMessageId: 'organizations.message',
      dataMessageId: 'organizations',
      navigatePath: !isMarketingUserRole && '/customers/?tab=organizations',
      nameKey: 'status__status',
      valueKey: 'count',
      fillKey: 'color_code',
    },
    {
      isVisible: isMarketingUserRole || devices?.includes(userAccessConst.device.listDeviceModels),
      dataKey: 'value',
      totalDataKey: 'total_devices',
      totalMessageId: 'devices.message',
      dataMessageId: 'devices',
      navigatePath: !isMarketingUserRole && '/device-models',
      nameKey: 'label',
      valueKey: 'count',
      fillKey: 'color_code',
    },
    {
      isVisible: isMarketingUserRole || users?.includes(userAccessConst.user.listUsers),
      dataKey: 'value',
      totalDataKey: 'total_pulse_users',
      totalMessageId: 'pulse_users.message',
      dataMessageId: 'pulse_users',
      navigatePath: !isMarketingUserRole && '/users',
      nameKey: 'label',
      valueKey: 'count',
      fillKey: 'color_code',
    },
  ];

  useEffect(() => {
    dispatch(getDashboard());
  }, []);

  const renderChart = ({ isVisible, dataMessageId, nameKey, valueKey, fillKey, ...chart }: any) => {
    if (!isVisible) {
      return null;
    }

    let chartData: IChartDataItem[] =
      dashboardResponse?.[dataMessageId]?.map((item: any) => ({
        name: `${item[nameKey]} (${item[valueKey]})`,
        value: item[valueKey] as number,
        fill: item[fillKey] as string,
      })) || [];

    return (
      <GridItem key={dataMessageId}>
        <PieChartComponent {...chart} prepareChartData={() => chartData} />
      </GridItem>
    );
  };

  return (
    <Box
      pt={{ lg: '20px' }}
      w={{ xs: '100vw', lg: toggle ? 'calc(100vw - 240px)' : 'calc(100vw - 100px)' }}
      overflowY="scroll"
      h={{ xs: 'calc(100vh - 100px)', lg: 'calc(100vh - 100px)' }}
    >
      <Box alignItems="center" pl="36px" mt="7px">
        <Text mr={{ xs: '3' }} fontSize={{ xs: '24px', xl: '24px' }} fontWeight="medium">
          <FormattedMessage id="dashboard.message" />
        </Text>
      </Box>

      <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 1fr)" pl="30px" gridRowGap={6}>
        {charts.map(renderChart)}
      </Grid>
    </Box>
  );
};

export default Dashboard;
