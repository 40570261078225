/* eslint-disable @typescript-eslint/no-explicit-any */
/* disabled rule for declaring type any because, */
/* Catch clause variable type annotation must be 'any' or 'unknown' if specified.*/
import { userRequest } from 'api';
import { Dispatch } from 'react';
import { setError } from '../../error/errorSlice';

import { fetchDashboardFail, fetchDashboardLoading, fetchDashboardSuccess } from '../Reducers/dashboardSlice';

interface IGetAllAssets {
  type: boolean | string;
}

const getDashboard = () => async (dispatch: Dispatch<IGetAllAssets>) => {
  dispatch(fetchDashboardLoading);

  try {
    dispatch(setError({ isError: false, errorMessage: '' }));
    const res = await userRequest.get('dashboard');

    if (res && res.status === 200) {
      dispatch(fetchDashboardSuccess(res.data.data));
    }
  } catch (error: any) {
    dispatch(setError({ isError: true, errorMessage: error?.message }));
    dispatch(fetchDashboardFail());
  }
};
export default getDashboard;
