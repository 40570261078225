import {
  Box,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  InputGroup,
  Image,
  Button,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
// import { exoWorksLogo } from '../../assets/icons';
import { customColor } from '../../assets/styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch, SharedUiState } from '../../redux/hooks';
import { getUserInfo, resetPassword } from '../../redux/login.thunk';
import { setResetPasswordSuccess } from '../../redux/login.slice';
import { exoWorksLogoDark, exoWorksLogoLight } from '../../../lib/assets/icons';

type ResetPasswordModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
export const ResetPasswordModal = ({ isOpen, onClose }: ResetPasswordModalProps) => {
  const intl = useIntl();
  const colors = customColor();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const dispatch = useDispatch<useAppDispatch>();
  const { baseUrl, resetPasswordLoading, singleSignOnLoading, userErrorMessage, resetPasswordSuccess } = useSelector(
    (state: SharedUiState) => state.login,
  );
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const modalBackgroundColor = useColorModeValue('#FFFFFF', '#34373E');
  // const modalHeaderImage = useColorModeValue(exoWorksLogo, exoWorksLogo);
  const { colorMode } = useColorMode();

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Use Yup to validate the email
    Yup.reach(validationSchema, 'email')
      .validate(newEmail)
      .then(() => {
        setEmailError(false);
      })
      .catch(() => {
        setEmailError(true);
      });
  };

  const handleURLUpdateOnIdleState = () => {
    const isUserIdle = searchParams.get('status');
    if (isUserIdle) {
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete('status');
      navigate({ search: newSearchParams.toString() });
    }
  };

  const onFocusEmailInput = () => {
    handleURLUpdateOnIdleState();
  };

  const handleOnClickButton = () => {
    if (email.length === 0 || emailError) {
      return;
    }
    dispatch(
      getUserInfo({
        email: email || '',
        baseUrl: baseUrl,
      }),
    );
    if (!userErrorMessage) {
      dispatch(
        resetPassword({
          email: email || '',
        }),
      );
    }
  };

  const onCloseModal = () => {
    onClose();
    setEmail('');
    dispatch(setResetPasswordSuccess(false));
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onCloseModal} border-radius="0.1px" scrollBehavior={'outside'}>
      <ModalOverlay />
      <ModalContent maxW="360px" background={modalBackgroundColor} padding="20px">
        <ModalHeader p="0px 0px 60px 0px">
          <Image src={colorMode === 'light' ? exoWorksLogoLight : exoWorksLogoDark} />
        </ModalHeader>
        <ModalBody backgroundColor="transparent" mb="140px" p="0px">
          <Box mb="40px">
            <Text
              color={colorMode === 'light' ? 'dark.500' : 'light.500'}
              fontSize="18px"
              fontWeight="500"
              letterSpacing="0.2px"
              mb="4px"
            >
              <FormattedMessage id="reset_password_header_text.message" />
            </Text>
            <Text
              color={colorMode === 'light' ? 'light.1300' : 'dark.1200'}
              fontSize="14px"
              fontWeight="500"
              letterSpacing="0.2px"
            >
              <FormattedMessage id="forget_password_sub_text.message" />
            </Text>
          </Box>
          <InputGroup>
            <Input
              placeholder={intl.formatMessage({ id: 'email.message' })}
              variant="flushed"
              type="email"
              fontSize={{ xs: '18px', lg: '18px' }}
              overflow="hidden"
              style={{ textOverflow: 'ellipsis' }}
              focusBorderColor={colors.primaryColor}
              borderBottomColor={colors.inputBorderColor}
              onFocus={onFocusEmailInput}
              borderWidth="0 0 1px 0"
              mb="1"
              color={colorMode === 'light' ? 'black' : 'white'}
              value={email}
              onChange={onChangeEmail}
            />
          </InputGroup>
          <Box height="21px">
            {emailError && (
              <Text color="common.200" fontSize={{ xs: '14px', lg: '14px' }}>
                <FormattedMessage id="please_use_valid_email.message" />
              </Text>
            )}
            {userErrorMessage && (
              <Text color="common.200" minH="30px" fontSize={{ xs: '14px', lg: '14px' }}>
                <FormattedMessage id={userErrorMessage} />
              </Text>
            )}
            {resetPasswordSuccess && (
              <Text color="light.100" minH="30px" fontSize={{ xs: '14px', lg: '14px' }}>
                <FormattedMessage id="reset_password_success.message" />
              </Text>
            )}
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="center" flexDir="column" p="0px">
          {resetPasswordSuccess && (
            <Text
              color={colorMode === 'light' ? 'dark.500' : 'light.500'}
              minH="30px"
              fontSize={{ xs: '14px', lg: '14px' }}
              onClick={() => {
                setEmail('');
                dispatch(setResetPasswordSuccess(false));
              }}
              cursor="pointer"
            >
              <FormattedMessage id="send_another_email.message" />
            </Text>
          )}
          <Button
            isLoading={resetPasswordLoading || singleSignOnLoading}
            onClick={resetPasswordSuccess ? onCloseModal : handleOnClickButton}
            background="#24B0FF"
            borderRadius="5px"
            width="100%"
            color="white"
            _hover={{ backgroundColor: '#24B0FF' }}
            disabled={true}
            letterSpacing="0.2px"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
          >
            {intl.formatMessage({
              id: resetPasswordSuccess ? 'back_to_login.message' : 'reset_password_button_text.message',
            })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
