import React, { ReactNode } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { SharedUiState } from '../../../lib/redux/hooks';
import { customColor } from '../../assets/styles/colors';
import { useNavigate } from 'react-router-dom';

type BannerProps = {
  heading?: string;
  message?: string;
  icon: React.ReactNode;
  innerTextComponent?: ReactNode;
};

const Banner = (props: BannerProps) => {
  const navigate = useNavigate();
  const isExoWorks = useSelector((state: SharedUiState) => state.login.isExoWorks);
  const colors = customColor();
  const backgroundColor = useColorModeValue(
    'white',
    'linear-gradient(0deg, rgba(255, 149, 0, 0.05) 0%, rgba(255, 149, 0, 0.05) 100%), #1A1C22',
  );
  const closeModal = () => {
    navigate('/');
  };

  const fontColor = useColorModeValue('black', '#C2EECD');
  return isExoWorks ? (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={!!props.message}
      onClose={closeModal}
      border-radius="0.1px"
      scrollBehavior={'outside'}
    >
      <ModalOverlay />
      <ModalContent maxW={{ xs: '80%', md: '60%', lg: '600px' }}>
        <ModalHeader fontSize="20px" fontWeight="700" pt="40px" pb="5px">
          <Text>
            <FormattedMessage id={props.heading} />
          </Text>
        </ModalHeader>
        <ModalBody backgroundColor="transparent">
          <Text fontSize="16px" fontWeight="500">
            <FormattedMessage id={props.message} />
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            type="button"
            width="100%"
            height="42px"
            borderRadius="10px"
            borderColor="#33333333"
            backgroundColor="#7676801F"
            data-testid="session-expired-ok-btn"
            onClick={closeModal}
          >
            <Text color="#333333" fontSize="18px" fontWeight="500">
              Okay
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Box h="84px">
      <Flex
        p={isExoWorks ? '0px' : '20px'}
        borderRadius="10px"
        background={isExoWorks ? 'transparent' : backgroundColor}
        boxShadow={isExoWorks ? 'none' : '0px 1px 20px 0px rgba(0, 0, 0, 0.60)'}
        border={isExoWorks ? 'none' : `1px solid ${colors.bannerWarningText}`}
        gap="16px"
        h="64px"
        alignItems="center"
      >
        {!isExoWorks && props.icon}
        {props.innerTextComponent}
        {props.message && (
          <Text color={isExoWorks ? colors.bannerWarningText : fontColor} fontSize="16px" fontWeight="500">
            <FormattedMessage id={props.message} />
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export default Banner;
