/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import {
  Box,
  Text,
  Checkbox,
  Wrap,
  WrapItem,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  Button,
  Icon,
  MenuList,
  Spinner,
  Image,
} from '@chakra-ui/react';
import { InputLabel } from 'components/InputField';
import { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import { FormattedMessage, useIntl } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { specialCharacterValidation, specialEmailValidation } from 'utils/validations/specialCharacterValidation';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Phone_Country_Constants } from 'utils/constants/phoneCountryConstants';

import {
  getOrganizationOwnerInfoByEmail,
  getOrganizationAutoPopulateData,
  getOrganizationNameData,
} from 'redux/organizations/Actions/organizationActions';
import { customer } from 'types/customer';
import { Country } from 'types/common-types';
import { InformationIcon } from 'assets/icons/icons';
import CustomToolTip from 'components/customToolTip/CustomToolTip';

interface AddOrgInfoProps {
  orgInfo: any;
  customerInfo: customer;
}
const AddOrgInfo = (props: AddOrgInfoProps) => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const intl = useIntl();
  const debounceTimeout = 500;
  const [isOwnerSameAsAdmin, setIsOwnerSameAsAdmin] = useState(true);
  const [isOwnerInfoLoading, setIsOwnerInfoLoading] = useState(false);
  const [isOwnerInfoEditable, setIsOwnerInfoEditable] = useState(true);
  const [customerEmail, setCustomerEmail] = useState('');
  const [orgNameInputValue, setOrgNameInputValue] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [PhoneNumberCountry, setPhoneNumberCountry] = useState<string>(Phone_Country_Constants.default_Phone_Country);
  const countries = useAppSelector((state) => state.common.countries);
  const languages = useAppSelector((state: RootState) => state.organization.languageData);
  const orgOwnerInfo = useAppSelector((state: RootState) => state.organization.orgOwnerInfo);
  const organizationAutoPopulateDataLoading = useAppSelector(
    (state: RootState) => state.organization.organizationAutoPopulateDataLoading,
  );
  const organizationsCode = useAppSelector((state: RootState) => state.organization.organizationsCode);
  const organizationsName = useAppSelector((state: RootState) => state.organization.organizationsName);
  const organizationNameDataError = useAppSelector((state: RootState) => state.organization.organizationNameDataError);
  const isOrganizationNameDataLoading = useAppSelector(
    (state: RootState) => state.organization.organizationNameDataLoading,
  );
  const { values, errors, handleBlur, handleChange, touched, setFieldValue, resetForm, validateForm } = props.orgInfo;

  const maxLengthCheck = (object: any) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };

  const reValidateFormFields = () => {
    // Re validate form post any field updated programatically.
    setTimeout(() => {
      validateForm();
    }, 300);
  };

  const prePopulateOrgNameCode = (firstName: string, lastName: string) => {
    // fetch OrgName & OrgCode from backend.
    dispatch(
      getOrganizationAutoPopulateData({
        first_name: firstName,
        last_name: lastName,
        tier: 1,
        status: undefined,
        success: false,
        message: undefined,
      }),
    );
  };

  const resetOwnerInfo = (validatForm = true) => {
    resetForm({
      ownerEmail: '',
      phone_code: 1,
      phone: '',
      ownerFirstName: '',
      ownerLastName: '',
      organizationName: '',
      orgCode: '',
    });
    setPhoneNumberCountry(Phone_Country_Constants.default_Phone_Country);
    setCountryCode('1');
    if (validatForm) {
      reValidateFormFields();
    }
  };

  const setCustomerInfoAsOwner = () => {
    setFieldValue('ownerEmail', props.customerInfo.customer_admin_email);
    setFieldValue('phone_code', props.customerInfo.customer_admin_phone_code.id);
    setFieldValue('phone', props.customerInfo.customer_admin_phone);
    setFieldValue('ownerFirstName', props.customerInfo.customer_admin_first_name);
    setFieldValue('ownerLastName', props.customerInfo.customer_admin_last_name);
    prePopulateOrgNameCode(props.customerInfo.customer_admin_first_name, props.customerInfo.customer_admin_last_name);
  };

  const handleIsAdminCheckbox = (e: any) => {
    setIsOwnerSameAsAdmin(e.target.checked);
    handleChange(e);
  };

  const handleOwnerEmailUpdate = (e: any) => {
    setCustomerEmail(e.target.value);
    handleChange(e);
  };

  const handlePhoneNumberCountry = (e: any, country: any) => {
    setPhoneNumberCountry(country.country_code);
    setCountryCode(country.id);
    setFieldValue('phone_code', country.id);
  };

  const handleOrgNameOnChange = (e: any) => {
    setOrgNameInputValue(e.target.value);
    handleChange(e);
  };

  const handleCountrySelect = (country: any) => {
    setFieldValue('country', {
      id: country.id,
      name: country.country_name,
    });
  };

  const handleLanguageSelect = (language: any) => {
    setFieldValue('language', {
      id: language.id,
      name: language.language_name,
    });
  };

  const handleBlurOnOwnerNameUpdate = (e: any) => {
    handleBlur(e);
    // featching organization name & Org Code on Owner FirstName & LastName update
    if (values.ownerFirstName.length > 0 && values.ownerLastName.length > 0) {
      prePopulateOrgNameCode(values.ownerFirstName, values.ownerLastName);
    }
  };

  useEffect(() => {
    // Auto-populating Org Name & Org Code
    if (!organizationAutoPopulateDataLoading) {
      setFieldValue('orgCode', organizationsCode || '');
      setFieldValue('organizationName', organizationsName || '');
      reValidateFormFields();
    }
  }, [organizationAutoPopulateDataLoading]);

  useEffect(() => {
    // Is customer admin as org owner checkbox
    if (isOwnerSameAsAdmin) {
      setCustomerInfoAsOwner();
    } else {
      resetOwnerInfo(false);
    }
    setIsOwnerInfoEditable(!isOwnerSameAsAdmin);
  }, [isOwnerSameAsAdmin]);

  useEffect(() => {
    // Fetch org owner by email from backend
    const getCustomerByEmail = setTimeout(() => {
      const validRegex = new RegExp(/\S+@\S+\.\S+/);
      if (validRegex.test(customerEmail)) {
        setIsOwnerInfoLoading(true);
        dispatch(getOrganizationOwnerInfoByEmail(customerEmail));
      }
    }, debounceTimeout);

    return () => clearTimeout(getCustomerByEmail);
  }, [customerEmail]);

  useEffect(() => {
    // Renders when get a response Owner search by email
    setIsOwnerInfoLoading(false);
    if (orgOwnerInfo && orgOwnerInfo.email) {
      setIsOwnerInfoEditable(false);
      prePopulateOrgNameCode(orgOwnerInfo.first_name, orgOwnerInfo.last_name);
    } else {
      setIsOwnerInfoEditable(true);
      setFieldValue('organizationName', '');
      setFieldValue('orgCode', '');
    }
    if (!isOwnerSameAsAdmin) {
      setFieldValue('ownerFirstName', orgOwnerInfo?.first_name || '');
      setFieldValue('ownerLastName', orgOwnerInfo?.last_name || '');
      setFieldValue('phone', orgOwnerInfo?.phone || '');
      setFieldValue('phone_code', orgOwnerInfo?.phone_code?.id || 1);
    }
  }, [orgOwnerInfo]);

  useEffect(() => {
    // Org Name validation from backend
    const getOrgName = setTimeout(() => {
      if (orgNameInputValue.length > 3) {
        const PostOrgName = {
          org_name: orgNameInputValue,
          status: undefined,
          success: false,
          message: undefined,
        };
        dispatch(getOrganizationNameData(PostOrgName));
      }
    }, debounceTimeout);

    return () => clearTimeout(getOrgName);
  }, [orgNameInputValue]);

  useEffect(() => {
    reValidateFormFields();
  }, [isOrganizationNameDataLoading]);

  const renderFlag = () => {
    return (
      <Image
        src={`assets/icons/flags/${PhoneNumberCountry}.svg`}
        alt={PhoneNumberCountry}
        fallbackSrc="assets/icons/flags/noFlag.svg"
        fallbackStrategy="onError"
        height="16px"
        width="20px"
      />
    );
  };

  const renderCountryList = () => {
    return (
      <>
        {countries &&
          countries.map((country: Country) => (
            <MenuItem
              key={country.id}
              value={country.country_phone_code}
              name={country.country_code}
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
              onClick={(e: any) => handlePhoneNumberCountry(e, country)}
            >
              <Box color="#33333" fontWeight="400" display="flex" alignItems="center" fontSize="15px">
                <Image
                  src={`assets/icons/flags/${country.country_code}.svg`}
                  alt={country.country_code}
                  fallbackSrc="assets/icons/flags/noFlag.svg"
                  fallbackStrategy="onError"
                  height="16px"
                  width="20px"
                  mr="10px"
                />
                <Text fontSize="14px">
                  {country.country_name} ({country.country_phone_code})
                </Text>
              </Box>
            </MenuItem>
          ))}
      </>
    );
  };

  const selectedCountryFlag = useMemo(renderFlag, [PhoneNumberCountry]);
  const countryMenuItems = useMemo(renderCountryList, [countries]);

  return (
    <Box>
      <Box display="flex" mt="20px" mb="5px" alignItems="center">
        <Checkbox
          name="isOwnerSameAsAdmin"
          onChange={handleIsAdminCheckbox}
          variant="themeBlueBg"
          isChecked={isOwnerSameAsAdmin}
        />
        <Text pl="10px" fontSize="13px" color={colors.textColor}>
          <FormattedMessage id="customer_admin_is_organization_owner.message" />
        </Text>
      </Box>
      {!isOwnerSameAsAdmin && (
        <Box>
          <Box pt="20px" pb="10px" fontWeight="700" fontSize="16px" minWidth={305} display="flex">
            <FormattedMessage id="organization_admin.message" />
            <Text ml="2px" mt="3px">
              <CustomToolTip
                label={intl.formatMessage({ id: 'organization_owner_info.message' })}
                icon={<InformationIcon color="#24B0FF" />}
                tooltipPlacement="right"
              />
            </Text>
          </Box>
          <Wrap spacingX="30px">
            <WrapItem>
              <Box minH="70px">
                <InputLabel value={values.ownerEmail} name="email" />
                <Box position="relative">
                  <Input
                    color={colors.subTextColor}
                    name="ownerEmail"
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderColor="#E0E0E0"
                    value={values.ownerEmail}
                    focusBorderColor={colors.primaryColor}
                    borderBottomColor={colors.inputBorderColor}
                    onKeyPress={specialEmailValidation}
                    borderWidth="0 0 1px 0"
                    onChange={handleOwnerEmailUpdate}
                    onBlur={handleBlur}
                    placeholder={intl.formatMessage({ id: 'email.message' })}
                    type="text"
                    fontSize="14px"
                    _placeholder={{ fontSize: '14px' }}
                    paddingTop="10px"
                    maxLength={128}
                    variant="flushed"
                    required
                    data-testid="orgForm-email"
                  />
                  {isOwnerInfoLoading && (
                    <Spinner position="absolute" right="5px" top="12px" h="16px" w="16px" color={colors.primaryColor} />
                  )}
                </Box>
                <Text fontSize="12px" color="#FB5654">
                  {touched.ownerEmail && errors.ownerEmail}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem>
              {isOwnerInfoEditable ? (
                <Box minH="70px">
                  <InputLabel value="-" name="phone" />
                  <Box display="flex" justifyContent="space-between" alignItems="center" width="305px" gap="10px">
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            isActive={isOpen}
                            as={Button}
                            paddingRight="5px"
                            width={
                              PhoneNumberCountry
                                ? { xs: '85vw', smd: '89vw', md: '90px' }
                                : { xs: '85vw', smd: '89vw', md: '305px' }
                            }
                            borderBottom=" 1px solid"
                            _focusVisible={{ borderBottomColor: colors.primaryColor }}
                            borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                            borderRadius="0px"
                            variant="flushed"
                            paddingTop="5px"
                            data-testid="orginfo-country"
                            rightIcon={
                              isOpen ? (
                                <Icon as={ChevronUpIcon} w={6} h={6} paddingTop="5px" color={colors.primaryColor} />
                              ) : (
                                <Icon as={ChevronDownIcon} w={6} h={6} paddingTop="5px" color={colors.primaryColor} />
                              )
                            }
                          >
                            <Box marginLeft="-10px" display="flex" h="40px" alignItems="center" mt="5px">
                              {selectedCountryFlag}
                              <Text fontSize="14px" ml="5px" fontWeight="400">
                                <FormattedMessage
                                  id={PhoneNumberCountry.toLowerCase() + '.message'}
                                  defaultMessage={PhoneNumberCountry}
                                />
                              </Text>
                            </Box>
                          </MenuButton>
                          <MenuList
                            width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                            h="200px"
                            overflowY="scroll"
                            fontSize="16px"
                            zIndex={9999}
                          >
                            {countryMenuItems}
                          </MenuList>
                        </>
                      )}
                    </Menu>
                    {
                      <Wrap style={{ overflow: 'visible', width: '205px' }}>
                        <WrapItem>
                          <Input
                            color={colors.subTextColor}
                            opacity="0.6"
                            name="country_code"
                            width={{ xs: '5vw', smd: '15vw', md: '50px' }}
                            value={
                              countryCode != ''
                                ? countries.find((item: any) => item.id == countryCode)?.country_phone_code
                                : Phone_Country_Constants.default_Phone_Country_Code
                            }
                            borderColor="#E0E0E0"
                            focusBorderColor={colors.primaryColor}
                            borderBottomColor={colors.inputBorderColor}
                            borderWidth="0 0 1px 0"
                            variant="flushed"
                            required
                            fontSize="15px"
                            paddingTop="5px"
                            data-testid="orgForm-phone"
                            readOnly
                          />
                          <Input
                            color={colors.subTextColor}
                            name="phone"
                            width={{ xs: '10vw', smd: '57vw', md: '160px' }}
                            onChange={handleChange}
                            placeholder={intl.formatMessage({ id: 'phone.message' })}
                            borderColor="#E0E0E0"
                            focusBorderColor={colors.primaryColor}
                            borderBottomColor={colors.inputBorderColor}
                            borderWidth="0 0 1px 0"
                            type="number"
                            min={10}
                            onInput={maxLengthCheck}
                            value={values.phone}
                            onBlur={handleBlur}
                            maxLength={10}
                            variant="flushed"
                            required
                            fontSize="16px"
                            paddingTop="5px"
                            data-testid="orgForm-phone"
                            pattern="[0-9]*"
                            inputMode="numeric"
                          />
                        </WrapItem>
                      </Wrap>
                    }
                  </Box>
                  <Text display="flex" fontSize="12px" color="#FB5654">
                    {touched.phone && errors.phone}
                  </Text>
                </Box>
              ) : (
                <Box minH="70px">
                  <InputLabel value={'-'} name="phone" />
                  <Text fontSize="12px" mt="10px" color={colors.textColor} minWidth={305}>
                    {orgOwnerInfo?.phone_code?.country_phone_code + ' ' + orgOwnerInfo?.phone}
                  </Text>
                </Box>
              )}
            </WrapItem>
            <WrapItem>
              {isOwnerInfoEditable ? (
                <Box minH="70px">
                  <InputLabel value={values.ownerFirstName} name="first_name" />
                  <Input
                    color={colors.subTextColor}
                    name="ownerFirstName"
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    borderColor="#E0E0E0"
                    focusBorderColor={colors.primaryColor}
                    borderBottomColor={colors.inputBorderColor}
                    borderWidth="0 0 1px 0"
                    onChange={handleChange}
                    value={values.ownerFirstName}
                    placeholder={intl.formatMessage({ id: 'first_name.message' })}
                    type="text"
                    textTransform="capitalize"
                    variant="flushed"
                    maxLength={32}
                    _placeholder={{ fontSize: '14px' }}
                    fontSize="14px"
                    paddingTop="10px"
                    onBlur={handleBlurOnOwnerNameUpdate}
                    required
                    data-testid="orgForm-fname"
                  />
                  <Text fontSize="12px" color="#FB5654">
                    {touched.ownerFirstName && errors.ownerFirstName}
                  </Text>
                </Box>
              ) : (
                <Box minH="70px">
                  <InputLabel value={'-'} name="first_name" />

                  <Text fontSize="12px" mt="10px" color={colors.textColor} minWidth={305}>
                    {orgOwnerInfo?.first_name}
                  </Text>
                </Box>
              )}
            </WrapItem>
            <WrapItem>
              {isOwnerInfoEditable ? (
                <Box minH="70px">
                  <InputLabel value={values.ownerLastName} name="last_name" />
                  <Input
                    color={colors.subTextColor}
                    name="ownerLastName"
                    value={values.ownerLastName}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'last_name.message' })}
                    borderColor="#E0E0E0"
                    focusBorderColor={colors.primaryColor}
                    borderBottomColor={colors.inputBorderColor}
                    borderWidth="0 0 1px 0"
                    textTransform="capitalize"
                    type="text"
                    _placeholder={{ fontSize: '14px' }}
                    fontSize="14px"
                    paddingTop="10px"
                    maxLength={32}
                    onBlur={handleBlurOnOwnerNameUpdate}
                    variant="flushed"
                    required
                    data-testid="orgForm-lname"
                  />
                  <Text fontSize="12px" color="#FB5654">
                    {touched.ownerLastName && errors.ownerLastName}
                  </Text>
                </Box>
              ) : (
                <Box minH="70px">
                  <InputLabel value={'-'} name="last_name" />

                  <Text fontSize="12px" mt="10px" color={colors.textColor} minWidth={305}>
                    {orgOwnerInfo?.last_name}
                  </Text>
                </Box>
              )}
            </WrapItem>
          </Wrap>
        </Box>
      )}

      <Box>
        <Box pt="10px">
          <Text fontWeight="700" fontSize="16px" minWidth={305}>
            Organization Information
          </Text>
        </Box>
        <Box mt="10px">
          <Wrap spacingX="30px">
            <WrapItem>
              <Box minH="70px">
                <InputLabel value={values.organizationName} name="org_name" />
                <Box position="relative">
                  <Input
                    minLength={4}
                    color={colors.subTextColor}
                    className="form-input"
                    name="organizationName"
                    _placeholder={{ fontSize: '14px' }}
                    fontSize="14px"
                    value={values.organizationName}
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    onChange={handleOrgNameOnChange}
                    onBlur={handleBlur}
                    placeholder={intl.formatMessage({ id: 'org_name.message' })}
                    borderColor="#E0E0E0"
                    focusBorderColor={colors.primaryColor}
                    borderBottomColor={colors.inputBorderColor}
                    borderWidth="0 0 1px 0"
                    type="text"
                    textTransform="capitalize"
                    paddingTop="10px"
                    onKeyPress={specialCharacterValidation}
                    variant="flushed"
                    required
                    maxLength={30}
                    data-testid="orginfo-name"
                  />
                  {(isOrganizationNameDataLoading || organizationAutoPopulateDataLoading) && (
                    <Spinner position="absolute" right="5px" top="12px" h="16px" w="16px" color={colors.primaryColor} />
                  )}
                </Box>
                <Box fontSize="12px" color="#FB5654">
                  <Text fontSize="12px" color="grey" float={'right'}>
                    {Math.abs(values.organizationName.length - 30)}
                  </Text>
                  <Text fontSize="12px" color="#FB5654">
                    {touched.organizationName && errors.organizationName}
                  </Text>
                </Box>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box display="flex" flexDirection="column" minH="70px">
                <InputLabel value={values.orgCode} name="org_code" />
                <Box position="relative">
                  <Input
                    name="orgCode"
                    width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                    color={colors.textColor}
                    value={values.orgCode}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'org_code.message' })}
                    type="text"
                    _placeholder={{ fontSize: '14px' }}
                    fontSize="14px"
                    fontWeight="400"
                    borderColor="#E0E0E0"
                    paddingTop="10px"
                    focusBorderColor={colors.primaryColor}
                    borderBottomColor={colors.inputBorderColor}
                    borderWidth="0 0 1px 0"
                    variant="flushed"
                    required
                    onBlur={handleBlur}
                    maxLength={16}
                    float="right"
                    data-testid="orginfo-code"
                  />
                  {organizationAutoPopulateDataLoading && (
                    <Spinner position="absolute" right="5px" top="12px" h="16px" w="16px" color={colors.primaryColor} />
                  )}
                </Box>
                <Text fontSize="12px" color="#FB5654">
                  {touched.orgCode && errors.orgCode}
                </Text>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box minH="70px">
                <InputLabel value={values.country?.name} name="country" />
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        isActive={isOpen}
                        as={Button}
                        width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                        borderBottom=" 1px solid"
                        _focusVisible={{ borderBottomColor: colors.primaryColor }}
                        borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                        borderRadius="0px"
                        fontSize="14px"
                        variant="flushed"
                        data-testid="orginfo-country"
                        rightIcon={
                          isOpen ? (
                            <Icon mr="-10px" as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                          ) : (
                            <Icon mr="-10px" as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                          )
                        }
                      >
                        {values.country?.name ? (
                          <Text
                            float="left"
                            marginLeft="-15px"
                            fontSize="14px"
                            color="#33333"
                            fontWeight="400"
                            paddingTop="10px"
                          >
                            {values.country?.name}
                          </Text>
                        ) : (
                          <Text float="left" marginLeft="-15px" fontWeight="400" fontSize="14px" color="#8c8c8c">
                            <FormattedMessage id="country.message" />
                          </Text>
                        )}
                      </MenuButton>
                      <MenuList
                        width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                        h="200px"
                        fontSize="14px"
                        overflowY="scroll"
                      >
                        {countries &&
                          countries.map((country: Country) => (
                            <MenuItem
                              key={country.id}
                              value={country.id}
                              name={country.country_name}
                              backgroundColor="transparent"
                              _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                              onClick={() => handleCountrySelect(country)}
                            >
                              {country.country_name}
                            </MenuItem>
                          ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box minH="70px">
                <InputLabel value={values.language?.name} name="language" />
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        isActive={isOpen}
                        as={Button}
                        width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                        borderBottom="1px solid"
                        _focusVisible={{ borderBottomColor: colors.primaryColor }}
                        borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                        borderRadius="0px"
                        variant="flushed"
                        data-testid="orginfo-language"
                        rightIcon={
                          isOpen ? (
                            <Icon mr="-10px" as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                          ) : (
                            <Icon mr="-10px" as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                          )
                        }
                      >
                        {values.language.name ? (
                          <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" paddingTop="10px">
                            {values.language.name}
                          </Text>
                        ) : (
                          <Text float="left" marginLeft="-15px" fontSize="14px" fontWeight="400" color="#8c8c8c">
                            <FormattedMessage id="language.message" />
                          </Text>
                        )}
                      </MenuButton>
                      <MenuList
                        width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                        h="200px"
                        overflowY="scroll"
                        fontSize="14px"
                      >
                        {languages &&
                          languages.map((language: any) => (
                            <MenuItem
                              key={language.id}
                              value={language.id}
                              name={language.language_name}
                              backgroundColor="transparent"
                              _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                              onClick={() => handleLanguageSelect(language)}
                              fontSize="14px"
                            >
                              {language.language_name}
                            </MenuItem>
                          ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box minH="80px">
                <InputLabel value="name" name="license_type" />
                <Text fontWeight="400" fontSize="14px" marginTop={3} minWidth={305}>
                  <FormattedMessage id="basic.message" />
                </Text>
              </Box>
            </WrapItem>
          </Wrap>
        </Box>
      </Box>
    </Box>
  );
};
export default AddOrgInfo;
