import { AbsoluteCenter, Box, Text } from '@chakra-ui/react';
import { customColor } from 'assets/styles/colors';
import CustomTable from 'components/customTable/CustomTable';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'redux/hooks';
import { updateFeature } from 'redux/orgFeatures/Actions/OrgFeaturesActions';
import { userAccessConst } from 'utils/constants/userAccessConst';

interface OrgFeaturesListViewProps {
  searchText: string;
}
const OrgFeaturesListView = ({ searchText }: OrgFeaturesListViewProps) => {
  const colors = customColor();

  const featuresData = useAppSelector((state) => state.orgFeatures.featureList);
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const hasAvailableEditAccess = userPermissions.organizations?.includes(userAccessConst.organization.addLicense);
  const data = Array.isArray(featuresData)
    ? featuresData &&
      featuresData.map((features: any) => {
        return {
          id: features.id,
          feature: features.name,
          feature_group: features.groupName || '-',
          feature_code: features.code,
          available: features.enabled,
          isUpdating: features.isUpdating,
          controlledByPulse: features.controlledByPulse,
        };
      })
    : [];
  const showNoMatchFound = searchText.length > 0 && !data?.length;
  const columns = [
    {
      field: 'feature',
      header: <FormattedMessage id="feature.message" />,
      link: false,
      twoRows: true,
      isCapitalize: true,
    },
    {
      field: 'feature_group',
      header: <FormattedMessage id="feature_group.message" />,
      link: false,
      twoRows: true,
      isCapitalize: true,
    },
    { field: 'feature_code', header: <FormattedMessage id="feature_code.message" />, link: false },
    {
      field: 'available',
      header: <FormattedMessage id="available.message" />,
      link: false,
      hasEditAccess: hasAvailableEditAccess,
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pr="20px"
      overflowY="scroll"
      h="calc(100vh - 295px)"
      data-testid="org-license-addons-data"
      ml="-15px"
    >
      {showNoMatchFound && (
        <AbsoluteCenter display="flex" justifyContent="center" w="100%">
          <Text
            opacity="0.5"
            color={colors.textColor}
            fontSize={{ xs: '16px', lg: '18px' }}
            data-testid="no-match-found"
          >
            <FormattedMessage id="no_matches_found.message" />
          </Text>
        </AbsoluteCenter>
      )}
      {data?.length > 0 && <CustomTable data={data} columns={columns} dispatchCallback={updateFeature} />}
    </Box>
  );
};

export default OrgFeaturesListView;
