export const userAccessConst = {
  sideNavigation: {
    organizations: 'organizations', // got renamed to customers, but still receiving organizations from the permissions API.
    devices: 'devices',
    bundles: 'bundles',
    users: 'users',
    settings: 'settings',
    analytics: 'analytics',
    communication: 'communication',
  },
  organization: {
    listOrg: 'read:organizations',
    addOrg: 'create:organizations',
    deleteOrg: 'delete:organizations',
    viewOrg: 'read:organizations',
    updateOrg: 'update:organizations',
    updateOrgStatus: 'update:organizations',

    listUsers: 'read:organization_users',
    viewUser: 'read:organization_users',
    listUserActivity: 'read:organization_users',
    resetUserPassword: 'update:organization_users',
    listAllUsrActivity: 'read:organization_users',

    listDevices: 'read:organization_devices',
    addDevice: 'create:organization_devices',
    viewDevice: 'read:organization_devices',
    updateDevice: 'update:organization_devices',
    unAssignDevice: 'update:organization_devices',
    listDeviceActivity: 'read:organization_devices',
    listAllDeviceActivity: 'read:organization_devices',
    listLicenses: 'read:organization_licenses',
    addLicense: 'create:organization_licenses',
  },
  device: {
    listDeviceModels: 'read:device_models',
    addDeviceModel: 'create:device_models',
    updateDeviceModel: 'update:device_models',
    deleteDeviceModel: 'delete:device_models',

    listDevices: 'read:devices',
    addDevice: 'create:devices',
    updateDevice: 'update:devices',
    deleteDevice: 'delete:devices',
    viewDevice: 'read:devices',
    unAssignDevice: 'update:devices',
    listDeviceActivity: 'read:devices',
  },
  customers: {
    listCustomers: 'read:customers',
    addCustomer: 'create:customers',
    updateCustomer: 'update:customers',
    viewCustomer: 'read:customers',
    deleteCustomer: 'delete:customers',
  },
  bundle: {
    listBundles: 'read:licenses',
    addBundle: 'create:licenses',
    updateBundle: 'update:licenses',
    viewBundle: 'read:licenses',
    deleteBundle: 'delete:licenses',
  },
  user: {
    listUsers: 'read:pulse_users',
    addUser: 'create:pulse_users',
    updateUser: 'update:pulse_users',
    updateUserStatus: 'update:pulse_users',
    viewUser: 'read:pulse_users',
    deleteUser: 'delete:pulse_users',
  },
  setting: {
    addPartnerKey: 'create:partner_keys',
    updatePartnerKey: 'update:partner_keys',
    deletPartnerKey: 'delete:partner_keys',
    getApiKey: 'create:partner_keys',
    viewPartnerKeys: 'read:partner_keys',
  },
};
