import { Box, Circle, Text, useColorModeValue } from '@chakra-ui/react';
import { PieChart, Pie, Legend, Tooltip, Label, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { customColor } from '../../../../../libs/shared-ui/src/lib/assets';
import { FormattedMessage } from 'react-intl';

interface IPieChartItem {
  color: string;
  value: number;
  payload: {
    isEmpty?: boolean;
    value: number;
  };
}

interface IRenderCustomizedLegendProps {
  payload: IPieChartItem[];
}

interface IPieChartComponentProps {
  dataKey: string;
  totalDataKey: string;
  totalMessageId: string;
  dataMessageId: string;
  navigatePath: string;
  prepareChartData: any;
}

const PieChartComponent = ({
  dataKey,
  totalDataKey,
  totalMessageId,
  dataMessageId,
  navigatePath,
  prepareChartData,
}: IPieChartComponentProps) => {
  const navigate = useNavigate();
  const colorus = customColor();
  const dashboardData = useAppSelector((state: any) => state.dashboard);

  const graphData = prepareChartData(dashboardData) ?? [
    { name: <FormattedMessage id={`no_${dataMessageId}_data.message`} />, value: 1, isEmpty: true },
  ];

  const RenderCustomizedLegend = ({ payload }: IRenderCustomizedLegendProps) => (
    <Box>
      <Text
        onClick={() => navigate(navigatePath)}
        cursor= {navigatePath && "pointer"}
        zIndex={1000}
        fontSize="30px"
        height="30px"
        mb="20px"
        color={colorus.primaryColor}
      >
        <FormattedMessage id={totalMessageId} />
      </Text>
      {payload.length &&
        payload.map((item: IPieChartItem, index: number) => (
          <Box display="flex" alignItems="center" pb="3px" key={index}>
            {!item?.payload?.isEmpty && <Circle size="9px" bg={item.color} mt="2px" mr="10px" color="white"></Circle>}
            <Text fontSize="12px" color={colorus.textColor}>
              {item.value}
            </Text>
          </Box>
        ))}
    </Box>
  );

  const CustomTooltip = ({ active, payload, label }: any) => {
    const payloadData = payload[0];
    if (active) {
      return (
        <Box
          className="custom-tooltip"
          style={{
            backgroundColor: '#ffff',
            padding: '5px',
            border: '1px solid #cccc',
          }}
        >
          <Text>{`${payloadData?.name.split('(')[0]} : ${payloadData.value}`}</Text>
        </Box>
      );
    }
    return null;
  };

  const handleClick = () => {
    navigate(navigatePath);
  };

  return (
    <Box w="100%" position="relative">
      <ResponsiveContainer width="100%" height={350}>
        <PieChart onClick={handleClick}>
          <Legend
            height={36}
            iconType="circle"
            content={<RenderCustomizedLegend payload={graphData} />}
            layout="vertical"
            align="center"
            verticalAlign="middle"
            iconSize={10}
            wrapperStyle={{
              left: '300px',
              top: dashboardData?.dashboardResponse?.[totalDataKey] ? '90px' : '50%',
            }}
          />
          {dashboardData?.dashboardResponse?.[totalDataKey] && <Tooltip content={<CustomTooltip />} />}
          <Pie
            data={graphData}
            cx={120}
            cy={200}
            className="pie-chart"
            innerRadius={60}
            outerRadius={120}
            fill={colorus.lightModeWhite}
            paddingAngle={0}
            dataKey={dataKey}
            isAnimationActive={true}
            stroke={dashboardData?.dashboardResponse?.[totalDataKey] ? 'none' : '#D9D9D9'}
            style={{ outline: 'none' }}
          >
            <Label
              value={dashboardData?.dashboardResponse?.[totalDataKey] || 0}
              position="center"
              onClick={handleClick}
              style={{
                fontSize: '28px',
                fill: useColorModeValue(colorus.lightModeBlue, colorus.darkModeBlue),
              }}
              className="label-top"
              fontSize="27px"
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default PieChartComponent;
