/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import {
  Box,
  Button,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useColorModeValue,
  Flex,
  Icon,
  Wrap,
  WrapItem,
  Input,
  Menu,
  Image,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { CircleRightDisabledIcon } from 'assets/icons/icons';
import { customColor } from 'assets/styles/colors';

import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchCustomers, postCustomerDetails } from 'redux/customers/cutomersAction';
import { CustomerPostDetails } from 'types/customer';
import { CiCircleCheck, CiCircleChevRight, CiCircleRemove } from 'react-icons/ci';
import { handleCustomerModal, postCustomerDetailsFail } from 'redux/customers/customersSlice';
import { customerSchema, initialValues } from './CustomerValidationSchema';
import CustomInputField from 'components/customInputField/CustomInputField';
import { InputLabel } from 'components/InputField';
import { Phone_Country_Constants } from 'utils/constants/phoneCountryConstants';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Country } from 'types/common-types';
import { specialEmailValidation } from 'utils/validations/specialCharacterValidation';

const CustomerAddModal = () => {
  const colors = customColor();
  const closeIconColor = useColorModeValue('light.300', 'dark.100');
  const blueColorMode = useColorModeValue(colors.lightModeBlue, colors.darkModeBlue);
  const blackAndWhiteColorMode = useColorModeValue('white', 'black');
  const [isSuccess, setIsSuccess] = useState(false);
  const [customerNameUpdate, setCustomerNameEdit] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const dispatch = useAppDispatch();
  const customerEmailValidation = useAppSelector((state) => state.customers.customerEmailValidation);
  const customerNameValidation = useAppSelector((state) => state.customers.customerNameValidation);
  const customerPostLoading = useAppSelector((state) => state.customers.isPostCustomerLoading);
  const customerPostFail = useAppSelector((state) => state.customers.isPostCustomerDetailsFail);
  const customerPostSuccess = useAppSelector((state) => state.customers.isPostCustomerDetailsSuccess);
  const countries = useAppSelector((state) => state.common.countries);
  const handleModal = useAppSelector((state) => state.customers.handleModal);
  const intl = useIntl();
  const [inputValues, setInputValues] = useState<{ [x: string]: string }>();
  const [PhoneNumberCountry, setPhoneNumberCountry] = useState<string>(Phone_Country_Constants.default_Phone_Country);

  const maxLengthCheck = (object: any) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };

  const renderCountryList = () => {
    return (
      <>
        {countries &&
          countries.map((state: any) => (
            <MenuItem
              key={state.id}
              value={state.country_phone_code}
              name={state.country_code}
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
              onClick={(e: any) => handlePhoneNumberCountry(e)}
            >
              <Box color="#33333" fontWeight="400" display="flex" alignItems="center" fontSize="15px">
                <Image
                  src={`assets/icons/flags/${state.country_code}.svg`}
                  alt={state.country_code}
                  fallbackSrc="assets/icons/flags/noFlag.svg"
                  fallbackStrategy="onError"
                  height="16px"
                  width="20px"
                  mr="10px"
                />
                <Text fontSize="14px">
                  {state.country_name} ({state.country_phone_code})
                </Text>
              </Box>
            </MenuItem>
          ))}
      </>
    );
  };

  const handlePhoneNumberCountry = (e: any) => {
    setPhoneNumberCountry(e.currentTarget.name);
    const val = e.currentTarget.value;
    setInputValues((prevState: any) => ({
      ...prevState,
      ['country_code']: countries.find((item: Country) => item.country_phone_code == val)?.id,
    }));
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: customerSchema(customerNameUpdate),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        setSubmitting(true);
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  const isDisbaled = !(
    formik.dirty &&
    formik.isValid &&
    !customerEmailValidation &&
    !customerPostFail &&
    !customerNameValidation
  );

  // The below labels and icons are changed as per the response from  the api in Modal submit button
  const getActionLabels = () => {
    if (customerPostLoading) {
      return { label: <FormattedMessage id="add_modal.message" />, icon: <Spinner /> };
    }
    if (customerPostSuccess) {
      return {
        label: <FormattedMessage id="done.message" />,
        icon: <CiCircleCheck style={{ width: '30px', height: '30px' }} />,
      };
    }
    if (customerPostFail) {
      return {
        label: <FormattedMessage id="failure.message" />,
        icon: <CiCircleRemove style={{ width: '30px', height: '30px' }} />,
      };
    }
    return {
      label: <FormattedMessage id="add_modal.message" />,
      icon: isDisbaled ? <CircleRightDisabledIcon /> : <CiCircleChevRight style={{ width: '30px', height: '30px' }} />,
    };
  };
  const actionLabels = getActionLabels();

  const getTheme = () => {
    if (isDisbaled || isFail) {
      return {
        color: '#BAC5CD',
        borderColor: '#BAC5CD',
        hoverBg: 'auto',
        hoverColor: 'auto',
      };
    }
    if (isSuccess) {
      return {
        color: blackAndWhiteColorMode,
        borderColor: blueColorMode,
        bgColor: blueColorMode,
        hoverBg: 'auto',
        hoverColor: 'auto',
      };
    }
    return {
      color: blueColorMode,
      borderColor: blueColorMode,
      hoverBg: blueColorMode,
      hoverColor: blackAndWhiteColorMode,
    };
  };
  const theme = getTheme();

  const handleAdd = () => {
    const postCustomerData: CustomerPostDetails = {
      customer_name: formik.values.customer_name,
      customer_admin_first_name: formik.values.first_name,
      customer_admin_last_name: formik.values.last_name,
      customer_admin_email: formik.values.email,
      customer_admin_phone: formik.values.phone,
      customer_admin_phone_code: inputValues?.country_code ? inputValues?.country_code : 1,
      customer_admin_country_code: inputValues?.country_code ? inputValues?.country_code : 1,
    };
    dispatch(postCustomerDetails(postCustomerData));
  };

  const handleModalClose = () => {
    formik.setTouched({}, false);
    formik.resetForm();
    setPhoneNumberCountry(Phone_Country_Constants.default_Phone_Country);
    dispatch(handleCustomerModal(false));
    setInputValues((prevState: any) => ({
      ...prevState,
      ['country_code']: Phone_Country_Constants.default_Phone_Country_Code,
    }));
  };

  useEffect(() => {
    if (customerPostSuccess) {
      setTimeout(() => {
        formik.setTouched({}, false);
        formik.resetForm();
        setPhoneNumberCountry(Phone_Country_Constants.default_Phone_Country);
        dispatch(handleCustomerModal(false));
        setInputValues((prevState: any) => ({
          ...prevState,
          ['country_code']: Phone_Country_Constants.default_Phone_Country_Code,
        }));
        dispatch(fetchCustomers());
      }, 1500);
    }
  });
  const renderFlag = () => {
    return (
      <Image
        src={`assets/icons/flags/${PhoneNumberCountry}.svg`}
        alt={PhoneNumberCountry}
        fallbackSrc="assets/icons/flags/noFlag.svg"
        fallbackStrategy="onError"
        height="16px"
        width="20px"
      />
    );
  };
  const handleKeyPress = (e: any) => {
    if (e.target.name == 'customer_name') {
      setCustomerNameEdit(true);
    } else if (e.target.name == 'email' || 'last_name' || 'first_name') {
      setCustomerNameEdit(false);
    }
  };

  useEffect(() => {
    if (customerPostFail) {
      dispatch(postCustomerDetailsFail(false));
    }
  }, [formik.values, formik.errors]);
  const selectedCountryFlag = useMemo(renderFlag, [PhoneNumberCountry]);
  const countryMenuItems = useMemo(renderCountryList, [countries]);
  return (
    <Box>
      <Modal
        closeOnOverlayClick={false}
        isOpen={handleModal}
        onClose={handleModalClose}
        border-radius="0.1px"
        scrollBehavior={'outside'}
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ xs: '368px', smd: '700px', md: '700px', lg: '700px', xl: '700px', dt: '700px' }}
          mt="80px"
        >
          <ModalHeader fontSize="24px" ml="8px">
            <FormattedMessage id="add_customers.message" />
          </ModalHeader>
          <ModalCloseButton
            color={closeIconColor}
            data-testid="close-ic"
            backgroundColor="transparent"
            onClick={handleModalClose}
          />
          <ModalBody ml="7px" backgroundColor="transparent">
            <Box>
              <Wrap spacingX="30px">
                <Box>
                  <InputLabel value={formik?.values?.customer_name} name={'customer_name'} />
                  <WrapItem>
                    <Box display="flex" flexDirection="column" minH="70px">
                      <CustomInputField
                        defaultValue={''}
                        name={'customer_name'}
                        isEditable={true}
                        onKeyPress={handleKeyPress}
                        maxLength={64}
                        handleOnChange={formik.handleChange}
                        handleOnBlur={formik.handleBlur}
                      />
                      <Text fontSize="12px" color="#FB5654" mt="-40px">
                        {formik.touched.customer_name && formik.errors.customer_name}
                        {!(formik.touched.customer_name && formik.errors.customer_name) && customerNameValidation ? (
                          <FormattedMessage id="name_already_exists.message" />
                        ) : (
                          ''
                        )}
                      </Text>
                    </Box>
                  </WrapItem>
                </Box>
              </Wrap>
              <Text fontSize="20px" fontWeight="700">
                <FormattedMessage id="customer_admin.message" />
              </Text>
              <Wrap spacing="30px" mt="20px">
                <Box>
                  <InputLabel value={formik.values.first_name} name={'first_name'} />
                  <WrapItem>
                    <Box display="flex" flexDirection="column" minH="70px">
                      <CustomInputField
                        defaultValue={''}
                        isEditable={true}
                        name={'first_name'}
                        onKeyPress={handleKeyPress}
                        maxLength={64}
                        handleOnChange={formik.handleChange}
                        handleOnBlur={formik.handleBlur}
                      />
                      <Text fontSize="12px" color="#FB5654" mt="-40px">
                        {formik.touched.first_name && formik.errors.first_name && formik.errors.first_name}
                      </Text>
                    </Box>
                  </WrapItem>
                </Box>
                <Box>
                  <InputLabel value={formik.values.last_name} name={'last_name'} />
                  <WrapItem>
                    <Box display="flex" flexDirection="column" minH="70px">
                      <CustomInputField
                        defaultValue={''}
                        isEditable={true}
                        name={'last_name'}
                        maxLength={64}
                        onKeyPress={handleKeyPress}
                        handleOnChange={formik.handleChange}
                        handleOnBlur={formik.handleBlur}
                      />
                      <Text fontSize="12px" color="#FB5654" mt="-40px">
                        {formik.touched.last_name && formik.errors.last_name && formik.errors.last_name}
                      </Text>
                    </Box>
                  </WrapItem>
                </Box>
                <Wrap spacing="30px">
                  <WrapItem>
                    <Box>
                      <InputLabel value={formik.values.email} name={'email'} />
                      <Input
                        color={colors.subTextColor}
                        name="email"
                        width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                        onKeyPress={specialEmailValidation}
                        maxLength={128}
                        fontSize="14px"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={intl.formatMessage({ id: 'email.message' })}
                        borderColor="#E0E0E0"
                        focusBorderColor={colors.primaryColor}
                        borderBottomColor={colors.inputBorderColor}
                        borderWidth="0 0 1px 0"
                        type="text"
                        variant="flushed"
                        required
                      />
                      <Box display="flex" flexDirection="column" minH="70px">
                        <Text fontSize="12px" color="#FB5654">
                          {formik.touched.email && formik.errors.email}
                          {!(formik.touched.email && formik.errors.email) && customerEmailValidation ? (
                            <FormattedMessage id="customer_email_error.message" />
                          ) : (
                            ''
                          )}
                        </Text>
                      </Box>
                    </Box>
                  </WrapItem>

                  <WrapItem>
                    <Box minH="70px">
                      <InputLabel value={formik.values.phone} name="phone" />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                        gap="10px"
                      >
                        <Menu>
                          {({ isOpen }) => (
                            <>
                              <MenuButton
                                isActive={isOpen}
                                as={Button}
                                paddingRight="5px"
                                width={
                                  PhoneNumberCountry
                                    ? { xs: '85vw', smd: '89vw', md: '90px' }
                                    : { xs: '85vw', smd: '89vw', md: '305px' }
                                }
                                borderBottom=" 1px solid"
                                _focusVisible={{ borderBottomColor: colors.primaryColor }}
                                borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                                borderRadius="0px"
                                variant="flushed"
                                paddingTop="5px"
                                data-testid="orginfo-country"
                                rightIcon={
                                  isOpen ? (
                                    <Icon as={ChevronUpIcon} w={6} h={6} paddingTop="5px" color={colors.primaryColor} />
                                  ) : (
                                    <Icon
                                      as={ChevronDownIcon}
                                      w={6}
                                      h={6}
                                      paddingTop="5px"
                                      color={colors.primaryColor}
                                    />
                                  )
                                }
                              >
                                <Box marginLeft="-10px" display="flex" h="40px" alignItems="center" mt="5px">
                                  {selectedCountryFlag}
                                  <Text fontSize="14px" ml="5px" fontWeight="400">
                                    <FormattedMessage
                                      id={PhoneNumberCountry.toLowerCase() + '.message'}
                                      defaultMessage={PhoneNumberCountry}
                                    />
                                  </Text>
                                </Box>
                              </MenuButton>
                              <MenuList
                                width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                                h="200px"
                                overflowY="scroll"
                                fontSize="16px"
                                zIndex={9999}
                              >
                                {countryMenuItems}
                              </MenuList>
                            </>
                          )}
                        </Menu>
                        {
                          <Wrap style={{ overflow: 'visible', width: '205px' }}>
                            <WrapItem>
                              <Input
                                color={colors.subTextColor}
                                opacity="0.6"
                                name="country_code"
                                width={{ xs: '5vw', smd: '15vw', md: '50px' }}
                                value={
                                  inputValues?.country_code
                                    ? countries.find((item: any) => item.id == inputValues.country_code)
                                        ?.country_phone_code
                                    : Phone_Country_Constants.default_Phone_Country_Code
                                }
                                borderColor="#E0E0E0"
                                focusBorderColor={colors.primaryColor}
                                borderBottomColor={colors.inputBorderColor}
                                borderWidth="0 0 1px 0"
                                variant="flushed"
                                required
                                fontSize="15px"
                                paddingTop="5px"
                                data-testid="orgForm-phone"
                                readOnly
                              />
                              <Input
                                color={colors.subTextColor}
                                name="phone"
                                width={{ xs: '10vw', smd: '57vw', md: '160px' }}
                                onKeyPress={(event: { key: string; preventDefault: () => void }) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={formik.handleChange}
                                placeholder={intl.formatMessage({ id: 'phone.message' })}
                                borderColor="#E0E0E0"
                                focusBorderColor={colors.primaryColor}
                                borderBottomColor={colors.inputBorderColor}
                                borderWidth="0 0 1px 0"
                                type="number"
                                min={10}
                                onInput={maxLengthCheck}
                                onBlur={formik.handleBlur}
                                maxLength={10}
                                variant="flushed"
                                required
                                fontSize="16px"
                                paddingTop="5px"
                                data-testid="orgForm-phone"
                                pattern="[0-9]*"
                                inputMode="numeric"
                              />
                            </WrapItem>
                          </Wrap>
                        }
                      </Box>
                      <Text display="flex" fontSize="12px" color="#FB5654">
                        {formik.touched.phone && formik.errors.phone && formik.errors.phone}
                      </Text>
                    </Box>
                  </WrapItem>
                </Wrap>
              </Wrap>
            </Box>
          </ModalBody>
          <ModalFooter backgroundColor="transparent" zIndex={999}>
            <Button
              variant="outline"
              type="button"
              width="195px"
              height="42px"
              borderRadius="20px"
              backgroundColor={theme?.bgColor ?? 'auto'}
              borderColor={theme?.borderColor}
              color={theme?.color}
              isDisabled={isDisbaled}
              fontSize="17px"
              float="right"
              data-testid="org-button-disabled"
              fill={blueColorMode}
              _hover={{
                cursor: isDisbaled ? 'not-allowed' : 'pointer',
                // ...(!isDisbaled && {
                bg: theme?.hoverBg,
                color: theme?.hoverColor,

                '&>div>svg>svg>circle': {
                  fill: blueColorMode,
                  stroke: blackAndWhiteColorMode,
                },
                '&>div>div>svg>svg>circle': {
                  fill: blueColorMode,
                  stroke: blackAndWhiteColorMode,
                },
                '&>div>svg>svg>path': {
                  fill: blackAndWhiteColorMode,
                  stroke: 'none',
                },
                '&>div>div>svg>svg>path': {
                  fill: blackAndWhiteColorMode,
                  stroke: 'none',
                },
              }}
              onClick={handleAdd}
              _disabled={{
                opacity: 1,
              }}
            >
              <Flex alignItems="center">
                <Text
                  fontSize="12px"
                  display="flex"
                  alignItems="center"
                  fontWeight="extrabold"
                  data-testid="org-button-text"
                  ml="-24px"
                >
                  {actionLabels?.label}
                </Text>
                <Box
                  position="absolute"
                  right="10px"
                  _hover={{
                    '&>div>div>svg>svg>circle': {
                      fill: 'white',
                      stroke: 'white',
                    },
                  }}
                  sx={{
                    '& > svg': {
                      width: '24px',
                      height: '24px',
                    },
                  }}
                >
                  {actionLabels?.icon}
                </Box>
              </Flex>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default CustomerAddModal;
