/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint rule for type "any" is disabled since event.target is undefined for type React.MouseEventHandler<HTMLButtonElement>
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Circle,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Wrap,
  WrapItem,
  Text,
  AbsoluteCenter,
  CircularProgress,
  Input,
} from '@chakra-ui/react';
import { InputLabel } from 'components/InputField';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import CustomInputField from 'components/customInputField/CustomInputField';
import { specialCharacterValidation, specialNumericDotValidation } from 'utils/validations/specialCharacterValidation';
import {
  handleBundelCreateButtonEnable,
  handleBundleCatalogueSCValidation,
  handleBundleEditDatePickerDay,
  handleBundleEditDatePickerMonth,
  handleBundleEditDatePickerYear,
  handleBundleNameSCValidation,
  handleCreateSoftwareBundleCatalogueInputValue,
  handleCreateSoftwareBundleDescriptionValue,
  handleCreateSoftwareBundleExpirationPeriodValue,
  handleCreateSoftwareBundleNameInput,
  handleCreateSoftwareBundleOrganizationTier,
  handleCreateSoftwareBundleStatusInputValue,
  handlePostUniqueNameValidation,
  handleSoftwareBundleCatalogueLengthValidation,
  handleSoftwareBundleNameLengthValidation,
  hanldeEditBundlePostApiFail,
  toggleBundleEditScreenModal,
} from 'redux/sofwareBundles/Reducers/SoftwareBundleSlice';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { customColor } from 'assets/styles/colors';
import { CalendarIcons, CircleCheckIconOrg } from 'assets/icons/icons';
import {
  editBundleData,
  getBundleData,
  getSingleBundleEditData,
  postUniqueCatalogueBundleValue,
  postUniqueNameBundleValue,
} from 'redux/sofwareBundles/Actions/SoftwareBundlesActions';
import { Error_Type_Enum } from 'utils/enum';
import { useParams } from 'react-router-dom';
import { userAccessConst } from 'utils/constants/userAccessConst';
import { RootState } from 'redux/Store/store';
import StatusInfo from 'components/status/StatusInfo';
import Loader from 'components/loader/Loader';

const SoftwareBundleEditScreenInputs = () => {
  const dispatch = useAppDispatch();
  const colors = customColor();
  const intl = useIntl();
  const { bundleId } = useParams();
  const [isTextChanged, setIsTextChanged] = useState<Boolean>(false);
  const singleBundleApiData = useAppSelector((state: any) => state.softwareBundles.singleBundleEditData);
  const [selectedBundleDay, setSelectedBundleDay] = useState<any>('');
  const bundleCatalogueValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleCatalogueInputValue);
  const [bundleStatusValue, setBundleStatusValue] = useState<any>(
    singleBundleApiData && singleBundleApiData?.status_name,
  );
  const bundleStatusData = useAppSelector((state) => state.softwareBundles.getSoftwareBundleStatusData);
  const [tierValue, setTierValue] = useState<any>(singleBundleApiData?.license_tier);
  const tierData = useAppSelector((state) => state.organization.tiersData);
  const calendarDateToday = utils(intl.locale).getToday();
  const [updatedState, setUpdatedState] = useState<any>('');
  const datePicker = useAppSelector((state) => state.softwareBundles.getBundleEditDatePickerDate);
  const deviceManufacturedYear = useAppSelector((state) => state.softwareBundles.getBundleEditDatePickerYear);
  const selectedDays = useAppSelector((state) => state.softwareBundles.getBundleEditDatePickerDay);
  const bundleGracePeriodData = useAppSelector((state) => state.softwareBundles.bundleGracePeriodData);
  const bundelnameValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleNameInputValue);
  const bundelStatusValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleStatusInputvalue);
  const bundelOrgTierValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleOrganizationTierValue);
  const bundelExpirationValue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleExpirationPeriodValue);
  const bundelDescriptionvalue = useAppSelector((state) => state.softwareBundles.getSoftwareBundleDescriptionValue);
  const bundelmanufactureYear = useAppSelector((state) => state.customInputFields.getDatePickerYear);
  const bundelmanufactureDay = useAppSelector((state) => state.customInputFields.getDatePickerDay);
  const bundelmanufactureMonth = useAppSelector((state) => state.customInputFields.getDatePickerMonth);
  const editBundlePostApiFail = useAppSelector((state) => state.softwareBundles.editBundlePostApiFail);
  const editPostBundleDataLoading = useAppSelector((state) => state.softwareBundles.editPostBundleDataLoading);
  const [gracePeriodValue, setGracePeriodValue] = useState<any>(
    singleBundleApiData && singleBundleApiData?.grace_period_days,
  );
  const uniqueValueLoading = useAppSelector((state) => state.softwareBundles.uniqueValueLoading);
  const id = useAppSelector((state) => state.softwareBundles.bundleEditScreenIDValue);
  const year: any = singleBundleApiData?.availability_date.slice(0, 4);
  const month: any = singleBundleApiData?.availability_date.slice(5, 7);
  const day: any = singleBundleApiData?.availability_date.slice(8, 10);
  const bundleNameSCValidation = useAppSelector((state) => state.softwareBundles.bundleNameSCValidation);
  const [isEnabled, setIsEnable] = useState<any>(false);
  const catalogueSCValidation = useAppSelector((state) => state.softwareBundles.catalogueSCValidation);
  const noSpecialCharactersInDescription = bundelDescriptionvalue.replace(/[^a-zA-Z0-9.+_ ,-]/g, '');
  const createBundleApiFail = useAppSelector((state) => state.softwareBundles.editBundlePostApiFail);
  const [bundleName, setBundleName] = useState<any>(singleBundleApiData && singleBundleApiData?.name);
  const [bundleCatalogue, setBundleCatalogue] = useState<any>(
    singleBundleApiData && singleBundleApiData?.catalog_number,
  );
  const selectedPage = useAppSelector((state: RootState) => state.softwareBundles.selectedPage);
  const isLoading = useAppSelector((state: RootState) => state.softwareBundles.editPostBundleDataLoading);
  const postuniqueNameValidation = useAppSelector((state) => state.softwareBundles.postuniqueNameValidation);
  const postuniqueCatalogueValidation = useAppSelector((state) => state.softwareBundles.postuniqueCatalogueValidation);
  const getSoftwareBundleCatalogueInputValue = useAppSelector(
    (state) => state.softwareBundles.getSoftwareBundleCatalogueInputValue,
  );
  const getSoftwareBundleNameInputValue = useAppSelector(
    (state) => state.softwareBundles.getSoftwareBundleNameInputValue,
  );
  const userPermissions = useAppSelector((state) => state.user.userPermissions);
  const [softwareBundleNotes, setSoftwareBundleNotes] = useState(singleBundleApiData?.description);
  const softwareBundleNameLengthValidation = useAppSelector(
    (state) => state.softwareBundles.softwareBundleNameLengthValidation,
  );
  const softwareBundleCatalogueLengthValidation = useAppSelector(
    (state) => state.softwareBundles.softwareBundleCatalogueLengthValidation,
  );
  const hasEditAccess = userPermissions.bundles?.includes(userAccessConst.bundle.updateBundle);
  const postData: any = {
    name: bundelnameValue ? bundelnameValue : singleBundleApiData?.name,
    status: bundelStatusValue ? bundelStatusValue : singleBundleApiData?.status_name,
    catalog_number: bundleCatalogueValue ? bundleCatalogueValue : singleBundleApiData?.catalog_number,
    license_tier: bundelOrgTierValue ? bundelOrgTierValue : singleBundleApiData?.license_tier?.id,
    grace_period_days: bundelExpirationValue ? bundelExpirationValue : singleBundleApiData?.grace_period_days,
    date: `${bundelmanufactureYear}-${bundelmanufactureMonth}-${bundelmanufactureDay}T00:00:00.000000Z`,
    availability_date: `${deviceManufacturedYear ? deviceManufacturedYear : year}-${datePicker ? datePicker : month}-${
      selectedDays ? selectedDays : day
    }T00:00:00.000000Z`,
    description: bundelDescriptionvalue ? bundelDescriptionvalue : singleBundleApiData?.description,
  };

  const handleSaveButton = () => {
    dispatch(editBundleData(postData, bundleId));
  };
  useEffect(() => {
    if (editBundlePostApiFail === 'SUCCESS') {
      setTimeout(() => {
        dispatch(toggleBundleEditScreenModal(false));
        dispatch(getBundleData(selectedPage));
        dispatch(hanldeEditBundlePostApiFail(false));
        setIsEnable(false);
      }, 1000);
    }
  }, [editBundlePostApiFail]);

  const handleExpirationPeriod = (e: any) => {
    setGracePeriodValue(e.target.name);

    dispatch(handleBundelCreateButtonEnable(true));
    dispatch(handleCreateSoftwareBundleExpirationPeriodValue(e.target.name));
    if (!postuniqueNameValidation && !postuniqueCatalogueValidation) {
      setIsEnable(true);
    }
  };
  const handleDescription = (e: any) => {
    setUpdatedState(e.target.value);
    setIsEnable(true);
    setSoftwareBundleNotes(e.target.value);
    dispatch(handleBundelCreateButtonEnable(true));
    dispatch(handleCreateSoftwareBundleDescriptionValue(e.target.value));
  };
  const handleBundleName = (e: any) => {
    const bundleName = e.currentTarget.value.replace(!/^[a-zA-Z0-9.+'_ ,-]*$/, '');
    setIsEnable(true);
    setIsTextChanged(true);
    dispatch(handleBundelCreateButtonEnable(true));
    dispatch(handleCreateSoftwareBundleNameInput(bundleName));

    if (!/^[a-zA-Z0-9.+'_ ,-]*$/.test(e.target.value)) {
      dispatch(handleBundleNameSCValidation(true));
    } else {
      dispatch(handleBundleNameSCValidation(false));
    }
    if (bundleName.length == 0) {
      dispatch(handleSoftwareBundleNameLengthValidation(true));
      setIsEnable(false);
    } else {
      dispatch(handleSoftwareBundleNameLengthValidation(false));
    }

    if (bundleName.trim() !== singleBundleApiData?.name) {
      dispatch(
        postUniqueNameBundleValue({
          field_name: 'name',
          field_value: bundleName,
        }),
      );
    }
  };

  const handleCatalogueValue = (e: any) => {
    const catalogueValue = e.target.value;
    setIsTextChanged(true);
    dispatch(handleBundelCreateButtonEnable(true));
    dispatch(handleCreateSoftwareBundleCatalogueInputValue(e.target.value));
    if (!/^[a-zA-Z0-9.+'_ ,-]*$/.test(e.target.value)) {
      dispatch(handleBundleCatalogueSCValidation(true));
    } else {
      dispatch(handleBundleCatalogueSCValidation(false));
    }
    if (catalogueValue.length == 0) {
      setIsEnable(false);
      dispatch(handleSoftwareBundleCatalogueLengthValidation(true));
    } else {
      dispatch(handleSoftwareBundleCatalogueLengthValidation(false));
    }
    if (catalogueValue.trim() !== singleBundleApiData?.catalog_number) {
      dispatch(
        postUniqueCatalogueBundleValue({
          field_name: 'catalogue_number',
          field_value: e.target.value,
        }),
      );
    }
  };

  function getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'short' });
  }
  useEffect(() => {
    setBundleCatalogue(singleBundleApiData && singleBundleApiData?.catalog_number);
    setBundleName(singleBundleApiData && singleBundleApiData?.name);
    setBundleStatusValue(singleBundleApiData && singleBundleApiData?.status);
    setGracePeriodValue(singleBundleApiData && singleBundleApiData?.grace_period_days);
    setTierValue(singleBundleApiData && singleBundleApiData?.license_tier);
  }, [id]);

  useEffect(() => {
    dispatch(getSingleBundleEditData(bundleId));
    setSoftwareBundleNotes(singleBundleApiData && singleBundleApiData?.description);
  }, []);

  useEffect(() => {
    if (
      postuniqueNameValidation == true ||
      bundleNameSCValidation == true ||
      postuniqueCatalogueValidation == true ||
      catalogueSCValidation ||
      softwareBundleNameLengthValidation == true ||
      softwareBundleCatalogueLengthValidation == true
    ) {
      setIsEnable(false);
    }
  }, [
    bundleNameSCValidation,
    postuniqueNameValidation,
    uniqueValueLoading,
    getSoftwareBundleNameInputValue,
    bundelnameValue,
    bundleCatalogueValue,
    postuniqueCatalogueValidation,
    softwareBundleNameLengthValidation,
    softwareBundleCatalogueLengthValidation,
  ]);

  useEffect(() => {
    if (
      !postuniqueNameValidation &&
      isTextChanged &&
      !postuniqueCatalogueValidation &&
      !bundleNameSCValidation &&
      !catalogueSCValidation
    ) {
      setIsEnable(!isEnabled);
    }
  }, [
    uniqueValueLoading,
    postuniqueNameValidation,
    postuniqueCatalogueValidation,
    catalogueSCValidation,
    bundleNameSCValidation,
  ]);
  return (
    <>
      {' '}
      {isLoading && <Loader isLoading={isLoading} />}
      <Box
        height="800px"
        display="block"
        overflowX="hidden"
        overflowY="scroll"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {singleBundleApiData ? (
          <>
            <Box display="block">
              <Box w="1200px" height="1050px" display="block" pl="28px">
                <Box mt="5px" minH="90px">
                  <Wrap display="flex" spacing="150px">
                    <WrapItem paddingTop="14px">
                      <Box>
                        <Text fontWeight="700" fontSize="24px" minWidth={305}>
                          Information
                        </Text>
                      </Box>
                    </WrapItem>
                    <WrapItem mt="20px" ml="-40px" minWidth={305} flexDirection="row-reverse">
                      <Wrap display="flex" spacingX="70px">
                        <WrapItem>
                          {hasEditAccess && (
                            <Box ml="5px">
                              <Button
                                isLoading={editPostBundleDataLoading}
                                width="82px"
                                h="24px"
                                border={isEnabled ? '1px solid grey' : '1px solid #24B0FF'}
                                borderRadius="15px"
                                borderColor={isEnabled ? '#24B0FF' : 'grey'}
                                color={isEnabled ? '#24B0FF' : 'grey'}
                                loadingText="Save"
                                colorScheme="teal"
                                variant="outline"
                                type="button"
                                spinnerPlacement="end"
                                onClick={handleSaveButton}
                                isDisabled={!isEnabled}
                                fontSize="14px"
                                fontWeight="500"
                              >
                                <Box display="flex" alignItems="center">
                                  <Text fontSize="14px" fontWeight="500" color={isEnabled ? '#24B0FF' : 'grey'}>
                                    Save
                                  </Text>

                                  {createBundleApiFail === Error_Type_Enum.Success && (
                                    <Icon w="20px" pl="4px" h="20px">
                                      <CircleCheckIconOrg />
                                    </Icon>
                                  )}
                                </Box>
                              </Button>
                            </Box>
                          )}
                        </WrapItem>
                      </Wrap>
                    </WrapItem>
                  </Wrap>
                  <Wrap display="flex" spacing="40px" spacingX="100px">
                    <WrapItem>
                      <Box display="flex" flexDirection="column" minH="70px" mt="20px">
                        <InputLabel value={singleBundleApiData?.name} name={'name'} />

                        <Box>
                          <CustomInputField
                            defaultValue={singleBundleApiData?.name}
                            key={singleBundleApiData?.name}
                            name={'name'}
                            textSize="14px"
                            placeholderSize="14px"
                            onKeyPress={specialCharacterValidation}
                            maxLength={100}
                            handleOnChange={handleBundleName}
                            handleOnBlur={undefined}
                            isEditable={hasEditAccess}
                          />
                          <Text fontSize="12px" color="#FB5654" mt="-40px">
                            {bundleNameSCValidation && !postuniqueNameValidation && (
                              <FormattedMessage id="no_special_characters.message" />
                            )}
                            {singleBundleApiData?.name !== getSoftwareBundleNameInputValue ? (
                              postuniqueNameValidation ? (
                                <FormattedMessage id="bundle_name_validation.message" />
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                            {softwareBundleNameLengthValidation && !postuniqueNameValidation ? (
                              <FormattedMessage id="field_validation.message" />
                            ) : (
                              ''
                            )}
                          </Text>
                        </Box>
                      </Box>
                    </WrapItem>
                    <WrapItem>
                      <Box display="flex" flexDirection="column" minH="90px" mt="20PX">
                        <InputLabel value={singleBundleApiData?.catalog_number} name={'catalogue'} />

                        <Box>
                          <CustomInputField
                            defaultValue={bundleCatalogue ? bundleCatalogue : ''}
                            name={'catalogue'}
                            onKeyPress={specialCharacterValidation}
                            maxLength={100}
                            handleOnChange={handleCatalogueValue}
                            handleOnBlur={undefined}
                            isEditable={hasEditAccess}
                            textSize="14px"
                            placeholderSize="14px"
                          />
                          <Text fontSize="12px" color="#FB5654" mt="-40px">
                            {catalogueSCValidation && !postuniqueCatalogueValidation && (
                              <FormattedMessage id="no_special_characters.message" />
                            )}
                            {singleBundleApiData?.catalog_number !== getSoftwareBundleCatalogueInputValue ? (
                              postuniqueCatalogueValidation ? (
                                <FormattedMessage id="bundle_catalogue_validation.message" />
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                            {softwareBundleCatalogueLengthValidation && !postuniqueCatalogueValidation ? (
                              <FormattedMessage id="field_validation.message" />
                            ) : (
                              ''
                            )}
                          </Text>
                        </Box>
                      </Box>
                    </WrapItem>
                  </Wrap>
                  <Wrap display="flex" spacing="40px" spacingX="100px">
                    <WrapItem>
                      <Box display="flex" flexDirection="column" minH="70px" w="305px">
                        <InputLabel value={singleBundleApiData?.status} name="status" />
                        <Box display="flex" alignItems="center" mt="13px" fontSize="14px">
                          <StatusInfo
                            indicatorSize="6px"
                            indicatorColor={
                              bundleStatusData.find((item: any) => item.id == singleBundleApiData?.status?.id)
                                ?.color_code
                            }
                            label={
                              bundleStatusData &&
                              bundleStatusData.find((item: any) => item.id == singleBundleApiData?.status?.id)?.status
                            }
                          />
                        </Box>
                      </Box>
                    </WrapItem>
                    <WrapItem>
                      <Box display="flex" flexDirection="column" minH="90px">
                        <InputLabel value={'month'} name="availabilty_date" />
                        <Box>
                          <Text mt="10px" fontSize="14px">
                            {getMonthName(datePicker ? datePicker : month)} {selectedDays ? selectedDays : day},{' '}
                            {deviceManufacturedYear ? deviceManufacturedYear : year}
                          </Text>
                        </Box>
                      </Box>
                    </WrapItem>
                  </Wrap>

                  <Wrap display="flex" spacing="40px" spacingX="100px">
                    <WrapItem>
                      <Box width="305px">
                        <InputLabel value={tierValue} name="license_type" />
                        <Text mt="10px" fontSize="14px">
                          {tierData && tierData.find((item: any) => item.id == tierValue?.id)?.tier_name}
                        </Text>
                      </Box>
                    </WrapItem>

                    <WrapItem>
                      <Box>
                        <InputLabel value={gracePeriodValue} name="expiration_grace_period" />
                        {hasEditAccess ? (
                          <Menu>
                            {({ isOpen }) => (
                              <>
                                <MenuButton
                                  isActive={isOpen}
                                  as={Button}
                                  width={{ xs: '85vw', smd: '89vw', md: '305px' }}
                                  borderBottom="1px solid"
                                  borderRadius="0px"
                                  _focusVisible={{ borderBottomColor: colors.primaryColor }}
                                  borderBottomColor={isOpen ? colors.primaryColor : colors.inputBorderColor}
                                  variant="flushed"
                                  data-testid="license-info"
                                  rightIcon={
                                    isOpen ? (
                                      <Icon as={ChevronUpIcon} w={6} h={6} color={colors.primaryColor} />
                                    ) : (
                                      <Icon as={ChevronDownIcon} w={6} h={6} color={colors.primaryColor} />
                                    )
                                  }
                                >
                                  {gracePeriodValue ? (
                                    <Text
                                      float="left"
                                      marginLeft="-15px"
                                      fontSize="14px"
                                      fontWeight="400"
                                      color={colors.subTextColor}
                                    >
                                      {gracePeriodValue}
                                      {<FormattedMessage id="days.message" />}
                                    </Text>
                                  ) : (
                                    <Text
                                      float="left"
                                      marginLeft="-15px"
                                      fontSize="14px"
                                      fontWeight="400"
                                      color="#8c8c8c"
                                    >
                                      <FormattedMessage id="expiration_grace_period.message" />
                                    </Text>
                                  )}
                                </MenuButton>
                                <MenuList width={{ xs: '85vw', smd: '89vw', md: '305px' }}>
                                  {bundleGracePeriodData &&
                                    bundleGracePeriodData.map((state: any, index: any) => (
                                      <MenuItem
                                        key={index}
                                        data-testid="menu-item"
                                        value={state.id}
                                        fontSize="14px"
                                        name={state.grace_period_days}
                                        backgroundColor="transparent"
                                        _hover={{ backgroundColor: 'transparent', color: colors.primaryColor }}
                                        onClick={handleExpirationPeriod}
                                      >
                                        {state.grace_period_days}
                                        {<FormattedMessage id="days.message" />}
                                      </MenuItem>
                                    ))}
                                </MenuList>
                              </>
                            )}
                          </Menu>
                        ) : (
                          <Text mt="10px">
                            {gracePeriodValue}
                            {<FormattedMessage id="days.message" />}
                          </Text>
                        )}
                      </Box>
                    </WrapItem>
                  </Wrap>
                </Box>
                <Box minH="60px" mt="30px">
                  <Wrap display="flex" spacing="40px" spacingX="150px">
                    <Box mt="10px" w="720px">
                      {hasEditAccess ? (
                        <>
                          <Box>
                            <InputLabel
                              value={singleBundleApiData?.description || softwareBundleNotes}
                              name={'notes_optional'}
                            />
                            <Input
                              variant="flushed"
                              key={singleBundleApiData?.description}
                              color={colors.subTextColor}
                              borderColor="#E0E0E0"
                              fontSize="14px"
                              _placeholder={{ fontsize: '14px' }}
                              maxLength={75}
                              defaultValue={singleBundleApiData?.description}
                              width={{ xs: '85vw', smd: '89vw', md: '720px' }}
                              focusBorderColor={colors.primaryColor}
                              borderBottomColor={colors.inputBorderColor}
                              borderWidth="0 0 0.5px 0"
                              onChange={handleDescription}
                              placeholder="Notes (Optional)"
                            />
                            <Text fontSize="12px" color="grey" display="flex" float="right" justifyContent="flex-end">
                              {softwareBundleNotes ? Math.abs(softwareBundleNotes?.length - 75) : 75}
                            </Text>
                          </Box>
                        </>
                      ) : (
                        <>
                          <InputLabel value={'-'} name={'notes'} />
                          <Text mt="10px">{singleBundleApiData?.description || '-'}</Text>
                        </>
                      )}
                    </Box>
                  </Wrap>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box position="relative" h="100%" display="flex" alignItems="center" justifyContent="center">
            <AbsoluteCenter p="4" axis="both">
              <CircularProgress isIndeterminate size="50px" />
            </AbsoluteCenter>
          </Box>
        )}
      </Box>
    </>
  );
};
export default SoftwareBundleEditScreenInputs;
