import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { SharedUiState, useAppDispatch } from '../../redux/hooks';
import { fetchSelectedOrganization } from '../../redux/login.slice';
import { customColor } from '../../assets/styles/colors';
import { UserInfo } from '../../../lib/types/types';
import { RightChervonIcon } from '../../assets/icons/icons';

const OrganizationItem = ({ org, onOrgselect }: { org: UserInfo; onOrgselect: (selectedOrg: any) => void }) => {
  const selectedOrg = useSelector((state: SharedUiState) => state.login.selectedOrg);
  const colors = customColor();
  const dispatch = useDispatch<useAppDispatch>();

  const handleOrg = (org: UserInfo) => {
    dispatch(fetchSelectedOrganization(org));
    onOrgselect(org);
  };

  return (
    <Box
      key={org.org_id}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderWidth="0.5px"
      borderColor={colors.lightModeBlue}
      borderRadius="14px"
      my="5px"
      width="100%"
      height="65px"
      onClick={() => handleOrg(org)}
      color={org.org_name === selectedOrg?.org_name ? colors.lightModeBlue : colors.textColor}
      boxShadow={org.org_name === selectedOrg?.org_name ? 'inset 0px 0px 8px 2px #24B0FF30' : ''}
      cursor="pointer"
      p="20px"
      _hover={{
        color: colors.lightModeBlue,
        boxShadow: 'inset 0px 0px 8px 2px #24B0FF30',

        '& .right_arrow': {
          display: 'block !important',
        },
      }}
    >
      <Text fontSize={{ xs: '16px', md: '18px' }} fontWeight={400}>
        {org.org_name}
      </Text>
      <Box className="right_arrow" display={org.org_name === selectedOrg?.org_name ? 'block' : 'none'} mt="-5px">
        <RightChervonIcon />
      </Box>
    </Box>
  );
};

export default OrganizationItem;
