import { useEffect } from 'react';
import {
  AbsoluteCenter,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Circle,
  CircularProgress,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getCustomerInfo } from 'redux/customers/cutomersAction';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { RightChervonIcon } from 'assets/icons/icons';
import { InputLabel } from 'components/InputField';

const CustomerInfo = () => {
  const { customerId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.customers.isLoading);
  const customerInfoData = useAppSelector((state) => state.customers.customerInfoData);

  const goBackToCustomers = () => {
    navigate(`/customers/?tab=customers`);
  };

  useEffect(() => {
    dispatch(getCustomerInfo(customerId || ''));
  }, []);

  return (
    <Box>
      <Box display="flex" h="100px" justifyContent="space-between" borderBottom="1px solid #3333331A">
        <Box data-testid="org-info-breadcumb" pl="15px">
          <Breadcrumb spacing="8px" pt="15px" separator={<ChevronRightIcon color="gray.500" />}>
            <BreadcrumbItem minH="20px">
              <BreadcrumbLink
                color="#24B0FF"
                position="absolute"
                onClick={goBackToCustomers}
                display="flex"
                fontSize="14px"
                alignItems="center"
                textTransform="capitalize"
              >
                <RightChervonIcon />
                <Text ml="5px">{'Customers'}</Text>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          {isLoading ? (
            ''
          ) : (
            <HStack gap="0px" minH="40px" mt="5px">
              <Circle size="9px" bg={'#1FD002'}></Circle>
              <Heading
                fontSize="36px"
                minW="200px"
                ml="7px"
                fontWeight="400"
                fontFamily="Helvetica"
                style={{ paddingLeft: '0px' }}
                textTransform="capitalize"
              >
                {customerInfoData?.customer_name}
              </Heading>
            </HStack>
          )}
        </Box>
      </Box>
      {!isLoading ? (
        <Box pl="22px" pt="5px">
          <Wrap display="flex" spacing="40px">
            <WrapItem paddingTop="14px" pl="10px">
              <Box>
                <Text fontWeight="700" fontSize="24px" minWidth={305}>
                  Information
                </Text>
              </Box>
            </WrapItem>
          </Wrap>
          <Box mt="30px" pl="10px">
            <Wrap display="flex" spacing="40px" minH="70px">
              <WrapItem>
                <Box minH="70px">
                  <InputLabel value="-" name="customer_name" />
                  <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                    {customerInfoData?.customer_name}
                  </Text>
                </Box>
              </WrapItem>
              <WrapItem>
                <Box minH="70px">
                  <InputLabel value="-" name="customer_id" />
                  <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                    {customerInfoData?.customer_id}
                  </Text>
                </Box>
              </WrapItem>
            </Wrap>
            <Wrap display="flex" spacing="40px" minH="70px">
              <WrapItem>
                <Box>
                  <InputLabel value="-" name="customer_admin_fname" />
                  <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                    {customerInfoData?.customer_admin_first_name}
                  </Text>
                </Box>
              </WrapItem>
              <WrapItem>
                <Box>
                  <InputLabel value="-" name="customer_admin_lname" />
                  <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                    {customerInfoData?.customer_admin_last_name}
                  </Text>
                </Box>
              </WrapItem>
            </Wrap>
            <Wrap display="flex" spacing="40px" minH="70px">
              <WrapItem>
                <Box>
                  <InputLabel value="-" name="customer_admin_email" />
                  <Text fontWeight="400" fontSize="14px" marginTop={2} minWidth={305}>
                    {customerInfoData?.customer_admin_email}
                  </Text>
                </Box>
              </WrapItem>
              <WrapItem>
                <Box>
                  <InputLabel value="-" name="customer_admin_phone" />
                  {customerInfoData?.customer_admin_phone ? (
                    <Stack marginTop={2} display="flex" direction={['row']} spacing="5px">
                      <Text fontSize="14px">{customerInfoData?.customer_admin_phone_code.country_phone_code} </Text>
                      <Text fontWeight="400" fontSize="14px" className="truncatee" display="flex">
                        {' '}
                        {customerInfoData?.customer_admin_phone}
                      </Text>
                    </Stack>
                  ) : (
                    '-'
                  )}
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      ) : (
        <Box>
          <AbsoluteCenter p="4" axis="both">
            <CircularProgress isIndeterminate size="50px" />
          </AbsoluteCenter>
        </Box>
      )}
    </Box>
  );
};
export default CustomerInfo;
