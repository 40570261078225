/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/Store/store';
import { customColor } from '../../assets/styles/colors';
import { CopyIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { fetchShowPasswordIds } from 'redux/settings/clientAccess/Reducers/clientAccessSlice';
import { InformationTextIcon } from 'assets/icons/icons';
import StatusInfo from 'components/status/StatusInfo';
import { LicenseTier } from 'types/LicenseTier';
import { Addon } from 'types/add-on';
import { useParams } from 'react-router-dom';
import { updateFeature } from 'redux/orgFeatures/Actions/OrgFeaturesActions';
import { FormattedMessage, useIntl } from 'react-intl';
import SubmitButton from '../../pages/organizations/addOrganizations/submitButton';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTable = ({
  data,
  columns,
  handleNavigateFromChild,
  dispatchCallback,
}: {
  data: any;
  columns: any;
  route?: any;
  handleNavigateFromChild?: any;
  twoRows?: any;
  infoData?: string;
  isCapitalize?: boolean;
  dispatchCallback?: any;
}) => {
  const colors = customColor();
  const theme = useAppSelector((state: RootState) => state.systemSettings.systemColorTheme);
  const dispatch = useAppDispatch();
  const isFeatureLoading = useAppSelector((state: RootState) => state.orgFeatures.isStatusUpdated);
  const showPasswordIds = useAppSelector((state: RootState) => state.clientAccess.showPasswordIds);
  const { orgId = '' } = useParams<string>();

  const [copiedPasswordId, setCopiedPasswordId] = useState<any>(null);
  const [copiedApiKeyId, setCopiedApiKeyId] = useState<any>(null);
  const [copiedClientId, setCopiedClientId] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleFeature, setToggleFeature] = useState<any>(() => () => {});
  const [modalMessage, setModalMessage] = useState('');
  const intl = useIntl();

  const renderTableRow = (row: any, col: any, index: number) => {
    const hanndlePassword = (text: any, id: any) => {
      navigator.clipboard.writeText(text);
    };
    const handleApiKey = (text: any, id: any) => {
      navigator.clipboard.writeText(text);
      setCopiedApiKeyId(id);
      setTimeout(() => {
        setCopiedApiKeyId(null);
      }, 1500);
    };

    const handleClientId = (text: any, id: any) => {
      navigator.clipboard.writeText(text);
    };
    if (col.field === 'values_bundles') {
      let data = [];
      if (typeof row[col.field] == 'object') {
        data = row[col.field];
      } else {
        data = [];
      }
      const ele = (
        <Box>
          {data.map((item: any, index: any) => (
            <Box key={index} display="flex" mb="10px">
              <Text width="30%">{item.value}</Text>
              <Text width="70%">{item.description}</Text>
            </Box>
          ))}
        </Box>
      );
      return <Td textTransform={col?.isCapitalize ? 'capitalize' : 'none'}>{ele}</Td>;
    }

    if (col.field === 'client_secret') {
      const ele = (
        <Box>
          <Box color={colors.textColor} display="flex" alignItems="center">
            <Text
              pr="10px"
              pt={showPasswordIds.includes(row.id) ? '2px' : '7px'}
              className={showPasswordIds.includes(row.id) ? 'truncatee' : 'truncateeForSecrent'}
            >
              {showPasswordIds.includes(row.id) ? row[col.field] : '*'.repeat(row[col.field].length)}
            </Text>
            <Text
              color={colors.primaryColor}
              cursor="pointer"
              fontSize="12px"
              onClick={() => dispatch(fetchShowPasswordIds(row.id))}
            >
              {showPasswordIds.includes(row.id) ? <ViewIcon /> : <ViewOffIcon />}
            </Text>
            <Popover>
              <PopoverTrigger>
                <Text
                  pl="4px"
                  pb="0px"
                  fontSize="12px"
                  color={colors.primaryColor}
                  cursor="pointer"
                  onClick={() => hanndlePassword(row.client_secret, row.id)}
                >
                  <CopyIcon />
                </Text>
              </PopoverTrigger>
              <PopoverContent width="150px">
                <PopoverArrow />
                <PopoverBody width="150px" fontSize="12px">
                  {' '}
                  Client Secret Copied!
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </Box>
      );
      return <Td textTransform={col?.isCapitalize ? 'capitalize' : 'none'}>{ele}</Td>;
    }

    if (col.field === 'client_id') {
      const ele = (
        <Box color={colors.textColor} display="flex" alignItems="center">
          <Text pr="10px" className="truncatee">
            {row[col.field]}
          </Text>
          {row[col.field] ? (
            <Popover>
              <PopoverTrigger>
                <Text
                  pb="6px"
                  fontSize="12px"
                  pl="4px"
                  color={colors.primaryColor}
                  cursor="pointer"
                  onClick={() => handleClientId(row.client_id, row.id)}
                >
                  <CopyIcon />
                </Text>
              </PopoverTrigger>
              <PopoverContent width="120px">
                <PopoverArrow />
                <PopoverBody width="150px" fontSize="12px">
                  Client Id Copied!
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            ''
          )}
        </Box>
      );
      return <Td textTransform={col?.isCapitalize ? 'capitalize' : 'none'}>{ele}</Td>;
    }

    if (col.field === 'available') {
      const checked = row[col.field];
      const handleToggle = (featureId: any, isEnabled: any) => {
        const featureMessageId = isEnabled ? 'feature_disable_Text.message' : 'feature_enable_Text.message';
        const formattedMessage = intl.formatMessage(
          { id: featureMessageId },
          { confirmation_text: `${row['feature'] || row['name']}` },
        );
        setModalMessage(formattedMessage);
        setIsModalOpen(true);

        const handleUpdateFeature = () => {
          if (orgId) {
            dispatch(dispatchCallback(orgId, featureId, !isEnabled));
          } else {
            dispatch(dispatchCallback(featureId, !isEnabled));
          }
          setIsModalOpen(false);
        };

        setToggleFeature(() => handleUpdateFeature);
      };
      const isFeatureUpdating = row['isUpdating'];
      const isControlledByPulse = row['controlledByPulse'];
      return (
        <Td>
          {' '}
          <Flex alignItems="center">
            <Text fontSize="13px" fontWeight="400" pr="8px">
              {row[col.field] ? 'Yes' : 'No'}
            </Text>
            {col.hasEditAccess && (
              <Switch
                size="sm"
                isChecked={checked}
                isDisabled={!isControlledByPulse || isFeatureUpdating}
                onChange={() => handleToggle(row.id, row[col.field])}
              />
            )}
            {isFeatureUpdating && <Spinner w="20px" h="20px" color={colors.primaryColor} ml="20px" />}
          </Flex>
        </Td>
      );
    }

    if (col.field === 'api_key') {
      const ele = (
        <Box color={colors.textColor} display="flex" alignItems="center">
          <Text pr="10px" className="truncatee">
            {row[col.field]}
          </Text>

          {row[col.field] ? (
            <Popover>
              <PopoverTrigger>
                <Text
                  pl="4px"
                  color={colors.primaryColor}
                  cursor="pointer"
                  fontSize="12px"
                  pb="4px"
                  onClick={() => handleApiKey(row.api_key, row.id)}
                >
                  <CopyIcon />
                </Text>
              </PopoverTrigger>

              <PopoverContent width="120px">
                <PopoverArrow />

                <PopoverBody width="150px" fontSize="12px">
                  API Key Copied!
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            ''
          )}
        </Box>
      );

      return <Td>{ele}</Td>;
    }

    if (col.field === 'last_used_by_devices') {
      const [first, sec] = row[col.field].split('*');

      const ele = (
        <Box color={colors.textColor}>
          <Text onClick={() => handleNavigate(row, col.field)} key={index} color={colors.primaryColor}>
            {first}
          </Text>

          <Text color={colors.paginationDisplay}>{sec}</Text>
        </Box>
      );
      return (
        <Td
          key={index}
          color={colors.primaryColor}
          cursor="pointer"
          fontWeight="400"
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          fontSize="13px"
          py="0px"
          height="60px"
          data-testid={`${row[col.field]}`}
        >
          {ele}
        </Td>
      );
    }

    if (col.field === 'trial') {
      const [first, sec] = row[col.field].split('*');

      const ele = (
        <Box display="flex" alignItems="start">
          {first == 'Yes' && <Image src="../../assets/icons/tick1.svg" mt="5px" alt="checkIcon" />}
          <Box color={colors.textColor} ml="4px">
            <Text pl={first == 'Yes' ? '0px' : '16px'}>{first}</Text>
            <Text color={colors.paginationDisplay}>{sec}</Text>
          </Box>
        </Box>
      );

      return (
        <Td
          key={index}
          color={colors.primaryColor}
          cursor="pointer"
          fontWeight="400"
          className="trial"
          fontSize="13px"
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          maxWidth="220px"
          height="60px"
          py="0"
          data-testid={`${row[col.field]}`}
        >
          {ele}
        </Td>
      );
    }

    if (col.field === 'two_rows' || col.field === 'device_model') {
      const [first, sec] = row[col.field].split('*');
      const ele = (
        <Box color={colors.textColor}>
          <Text>{first}</Text>

          <Text color={colors.paginationDisplay} fontSize="11px">
            {sec ? (col?.field === 'two_rows' || 'device_model' ? 'HW Version' : '') + ' ' + sec : ''}
          </Text>
        </Box>
      );

      return (
        <Td
          key={index}
          color={colors.primaryColor}
          fontWeight="400"
          fontSize="13px"
          py="0px"
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          height="60px"
          data-testid={`${row[col.field]}`}
        >
          {ele}
        </Td>
      );
    }

    if (col.field === 'two_rows_1') {
      const [first, sec] = row[col.field].split('*');
      const version = sec ? 'iOS' + ' ' + sec : '';
      const mobile_deivce_info = (
        <>
          <Text>{first}</Text>
          <Text color={colors.paginationDisplay} fontSize="11px">
            {version}
          </Text>
        </>
      );
      return (
        <Td
          key={index}
          fontWeight="400"
          fontSize="13px"
          color={colors.textColor}
          py="0px"
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          height="60px"
          data-testid={`${row[col.field]}`}
        >
          {mobile_deivce_info}
        </Td>
      );
    }
    if (col.field === 'platform_info') {
      // we need to remove this static text "iOS" and mobile check condition
      const version = row.platformVersionInfo
        ? (row.platform === 'mobile' ? 'iOS' : '') + ' ' + row.platformVersionInfo
        : '';
      const platform_info = (
        <>
          <Text>{row.platform_info}</Text>
          <Tooltip label={version} data-testid="tooltip-event" fontSize="11px">
            <Text cursor="pointer" fontSize="11px" color={colors.paginationDisplay}>
              {version}
            </Text>
          </Tooltip>
        </>
      );
      return (
        <Td
          key={index}
          fontWeight="400"
          color={colors.textColor}
          fontSize="13px"
          py="0px"
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          height="60px"
          data-testid={`${row[col.field]}`}
        >
          {platform_info}
        </Td>
      );
    }
    if (col.field === 'device_status') {
      const statusColor = row[col.field];
      const statusColorArray = statusColor.split('*');
      const status = statusColorArray[0];
      const color = statusColorArray[1];

      return (
        <Td
          onClick={() => handleNavigateFromChild(row, col.field)}
          key={index}
          color={colors.primaryColor}
          className="statusColumn"
          fontWeight="400"
          fontSize="13px"
          py="0px"
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          height="60px"
          data-testid={`${row[col.field]}`}
        >
          <StatusInfo indicatorSize="8px" label={status} indicatorColor={color} />
        </Td>
      );
    }

    if (col.field === 'device_serial_number') {
      const serialNumber = row[col.field];
      return (
        <Td
          key={index}
          color={col.link && serialNumber && serialNumber != '-' ? colors.primaryColor : colors.textColor}
          data-testid="device_serial"
          fontWeight="400"
          fontSize="13px"
          onClick={() => handleNavigateFromChild(row, col.field)}
          cursor={col.link && 'pointer'}
          py="0px"
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          height="60px"
          minWidth="120px"
        >
          <Text>{serialNumber}</Text>
        </Td>
      );
    }

    if (col.field === 'last_used_by') {
      const lastUsedBy = row[col.field];
      const lastUsedByArray = lastUsedBy.split('*');
      const name = lastUsedByArray[0];
      const date = lastUsedByArray[1];
      const ele = (
        <>
          <Box onClick={() => handleNavigateFromChild(row, col.field)}>
            <Text
              key={index}
              color={colors.primaryColor}
              cursor="pointer"
              fontWeight="400"
              fontSize="13px"
              py="0px"
              maxWidth="100px"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {name}
            </Text>

            <Text fontSize="13px" py="0px" color="#33333399">
              {date}
            </Text>
          </Box>
        </>
      );

      return (
        <Td
          sx={{
            padding: '0px 24px',
          }}
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
        >
          {ele}
        </Td>
      );
    }

    const handleNavigate = (row: any, field: any) => {
      handleNavigateFromChild(row, field);
    };

    if (col.field === 'firmware') {
      const [firmwareVersion, appVersion] = row[col.field].split('*');
      const el = (
        <Box>
          <Text fontSize="13px">{firmwareVersion ? firmwareVersion : ''}</Text>

          <Text fontSize="11px" color={colors.paginationDisplay}>
            {appVersion ? appVersion : ''}
          </Text>
        </Box>
      );
      return (
        <Td
          key={index}
          color={colors.textColor}
          data-testid="firmware-column"
          fontWeight="400"
          fontSize="13px"
          py="0px"
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          height="60px"
          minWidth="160px"
        >
          {el}
        </Td>
      );
    }
    if (col.field === 'event') {
      const deviceHealth = row['device_health'];
      let healthInfo = '';
      if (deviceHealth) {
        const healthData = JSON.parse(row['device_health']);
        const temp = healthData['maxAsicTemp'];
        const batteryCharge = healthData['batteryCharge'];
        healthInfo = temp ? temp + ' - ' + batteryCharge : '';
      }

      const el = (
        <Box>
          <Tooltip label={`${row[col.field]}`} data-testid="tooltip-event">
            <>
              <Text
                isTruncated
                sx={{
                  '&::after': {
                    content: '""',
                    display: 'block',
                  },
                }}
              >
                {row[col.field]}
              </Text>
              {deviceHealth && (
                <Text color={colors.paginationDisplay} fontSize="11px">
                  {healthInfo}
                </Text>
              )}
            </>
          </Tooltip>
        </Box>
      );
      return (
        <Td
          key={index}
          color={colors.textColor}
          data-testid="event"
          fontWeight="400"
          fontSize="13px"
          py="0px"
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          height="60px"
          minWidth="250px"
        >
          {el}
        </Td>
      );
    }
    if (col.field === 'user_name') {
      const isValidLink = col.link && !!(row['orgId'] && row['userId']);
      return (
        <Td
          key={index}
          color={isValidLink ? colors.primaryColor : colors.textColor}
          fontWeight="400"
          fontSize="13px"
          onClick={() => isValidLink && handleNavigateFromChild(row, col.field)}
          py="0px"
          cursor={isValidLink ? 'pointer' : 'auto'}
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          height="60px"
        >
          {row[col.field]}
        </Td>
      );
    }
    if (col.field === 'status') {
      const status = row[col.field];

      return (
        <Td
          key={index}
          color={colors.primaryColor}
          className="statusColumn"
          fontWeight="400"
          fontSize="13px"
          py="0px"
          height="60px"
          data-testid="status_column"
        >
          <StatusInfo indicatorSize="8px" label={status.status} indicatorColor={status.color_code} />
        </Td>
      );
    }
    if (col.field === 'supportedTiers') {
      const supportedTiers = row[col.field];
      return (
        <Td key={index} fontWeight="400" fontSize="13px" py="0px" height="60px" data-testid="supported_tiers">
          {supportedTiers.length ? (
            <Text
              style={{
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
            >
              {supportedTiers.map((tier: LicenseTier) => tier.tierName).join(', ')}
            </Text>
          ) : (
            '-'
          )}
        </Td>
      );
    }
    if (col.field === 'role') {
      const roles = row[col.field];
      const rolesArray = roles.split(',').map((roleName: string) => roleName.trim());
      return (
        <Td key={index} fontWeight="400" fontSize="13px" py="0px" height="60px" data-testid="roles">
          {rolesArray.length ? (
            <>
              {rolesArray.map((roleName: string, index: number) => (
                <Text key={index} py="3px">
                  {roleName}
                </Text>
              ))}
            </>
          ) : (
            '-'
          )}
        </Td>
      );
    }
    if (col.field === 'bundles') {
      const roles = row[col.field];
      const rolesArray = roles.split(',').map((roleName: string) => roleName.trim());
      return (
        <Td key={index} fontWeight="400" fontSize="13px" py="0px" height="60px" data-testid="roles">
          {rolesArray.length ? (
            <>
              {rolesArray.map((roleName: string, index: number) => (
                <Text key={index} py="3px">
                  {roleName}
                </Text>
              ))}
            </>
          ) : (
            '-'
          )}
        </Td>
      );
    }
    if (col.field === 'featureAddon') {
      const addon = row[col.field];

      return (
        <Td key={index} fontWeight="400" fontSize="13px" py="0px" height="60px" data-testid="feature_addons">
          {addon ? (
            <Text
              key={index}
              cursor={col.link ? 'pointer' : ''}
              onClick={() => handleNavigateFromChild(row, col.field)}
              color={col.link ? colors.primaryColor : colors.textColor}
            >
              {addon.name}
            </Text>
          ) : (
            '-'
          )}
        </Td>
      );
    }
    if (col?.link) {
      return (
        <Td
          onClick={() => row[col.field]?.length && handleNavigate(row, col.field)}
          key={index}
          color={row[col.field] == '-' || row[col.field] == 'N/A' ? colors.textColor : colors.primaryColor}
          cursor={row[col.field] == '-' || row[col.field] == 'N/A' ? '' : 'pointer'}
          fontWeight="400"
          fontSize="13px"
          py="0px"
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          height="60px"
          data-testid={`${row[col.field]}`}
        >
          {row[col.field]}
        </Td>
      );
    } else {
      return (
        <Td
          key={index}
          color={colors.textColor}
          data-testid={`${row[col.field]}`}
          fontWeight="400"
          fontSize="13px"
          textTransform={col?.isCapitalize ? 'capitalize' : 'none'}
          py="0px"
          height="60px"
        >
          {row[col.field]}{' '}
        </Td>
      );
    }
  };
  return (
    <Box ml="15px">
      <TableContainer data-testid="table-view-container" overflowX="unset" overflowY="unset">
        <Table data-testid="organization-table">
          <Thead data-testid="table-header" overflowX="scroll" className={`${theme === 'dark' ? 'dark' : ''}`}>
            <Tr
              sx={{
                '& :first-of-type': {
                  paddingLeft: '0px',
                },
              }}
            >
              {columns &&
                columns.map(
                  (
                    {
                      field,
                      header,
                      infoData,
                      isCapitalize = true,
                    }: {
                      field: string;
                      header: any;
                      infoData?: string;
                      isCapitalize?: boolean;
                    },
                    index: number,
                  ) =>
                    infoData ? (
                      <Th
                        color={colors.tableHeadColor}
                        textTransform={isCapitalize ? 'capitalize' : 'none'}
                        fontWeight="400"
                        className={field}
                        data-testid={header}
                        fontSize="13px"
                        py="0px"
                        height="60px"
                        key={index}
                      >
                        <Box display="flex">
                          <Text mb="2px" mr="3px">
                            {header}
                          </Text>
                          <Tooltip
                            label={infoData}
                            fontSize="12px"
                            padding="12px"
                            minH="50px"
                            borderRadius="10px"
                            boxShadow="0px 4px 17px rgba(0, 0, 0, 0.08)"
                            bg="white"
                            color={colors.tableHeadColor}
                            data-testid="tooltip-orgName"
                          >
                            <span style={{ cursor: 'pointer' }}>
                              <InformationTextIcon />
                            </span>
                          </Tooltip>
                        </Box>
                      </Th>
                    ) : (
                      <Th
                        color={colors.tableHeadColor}
                        textTransform={isCapitalize ? 'capitalize' : 'none'}
                        fontWeight="400"
                        className={field}
                        data-testid={header}
                        fontSize="13px"
                        py="0px"
                        height="60px"
                        key={index}
                      >
                        <Text mb="2px"> {header}</Text>
                      </Th>
                    ),
                )}
            </Tr>
          </Thead>
          <Tbody overflowX="scroll" data-testid="table-body">
            {data &&
              data.map((row: any, index: number) => (
                <Tr key={index} minHeight="60px" maxH="60px">
                  {columns.map((col: any, index: number) => (
                    <React.Fragment key={index}>{renderTableRow(row, col, index)}</React.Fragment>
                  ))}
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      {data ? null : <Text data-testid="no-table-data">No Row to show :)</Text>}

      <Box>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Text fontSize="16px">
                <FormattedMessage id="confirmation_feature_text.message" />
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontSize="15px" color={colors.paginationDisplay}>
                {modalMessage}
              </Text>
            </ModalBody>
            <ModalFooter>
              <SubmitButton label={<FormattedMessage id="save.message" />} handleSubmit={toggleFeature} />
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default CustomTable;
