import React from 'react';
import { Select, SingleValue } from 'chakra-react-select';
import { SystemStyleObject } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

type Option<T> = {
  value: T;
  label: string;
};

type SingleSelectDropdownProps<T> = {
  options: Option<T>[];
  onHandleChange: (option: SingleValue<Option<T>>) => void;
};

export const SingleSelectDropdown = <T,>({ options, onHandleChange }: SingleSelectDropdownProps<T>) => {
  const intl = useIntl();

  const localizedOptions: SingleValue<Option<T>>[] = options.map((option) => ({
    value: option?.value,
    label: intl.formatMessage({ id: option?.label }),
  }));

  const placeholder = localizedOptions && localizedOptions.length > 0 ? localizedOptions[0]?.label : 'Select';

  return (
    <Select
      data-test-id="single-select-dropdown"
      options={localizedOptions}
      placeholder={placeholder}
      variant="flushed"
      onChange={(value) => onHandleChange(value)}
      isSearchable={false}
      chakraStyles={{
        control: (provided: SystemStyleObject) => ({
          ...provided,
          transitionDuration: 0,
          width: 'auto',
          outline: 'none',
        }),
        menu: (provided) => ({
          ...provided,
        }),
        menuList: (provided) => ({
          width: 'auto',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          borderWidth: 0,
          borderRadius: '10px',
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: '#252831',
          color: state.isSelected ? 'white' : 'rgba(231, 233, 237, 0.8)',
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
        }),
        group: (provided) => ({
          ...provided,
          backgroundColor: '#252831',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: 'rgba(231, 233, 237, 0.8)',
        }),
      }}
    />
  );
};
