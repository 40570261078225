import { Box, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getFeaturesList } from 'redux/orgFeatures/Actions/OrgFeaturesActions';
import { FormattedMessage } from 'react-intl';
import Search from 'components/customSearch/Search';
import OrgFeaturesListView from './data/OrgFeaturesListView';
import TableInformation from 'components/pagination/tableInfo/TableInformation';
import DesktopPaginate from 'components/pagination/desktop/DesktopPagination';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/loader/Loader';
import MobilePagination from 'components/pagination/mobile/MobilePagination';
import LandingScreen from 'components/landingPage/LandingSreen';
import { handleOrgLicenseModalPopup } from 'redux/organiztionLicenes/Reduces/orgLicenseSlice';

const OrgFeatures = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgId = '' } = useParams<string>();
  const theme = useAppSelector((state) => state.systemSettings.systemColorTheme);
  const featuresData = useAppSelector((state) => state.orgFeatures);
  const isLoading = useAppSelector((state) => state.orgFeatures.isLoading);
  const paginationData = useAppSelector((state) => state.orgFeatures.pagination);

  const [searchText, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const featureList = featuresData.featureList;
  const showEmptyMessage = !isLoading && !searchText.length && !featuresData?.pagination?.count;

  const handleSearchValue = (value: string) => {
    setSearchText(value);
    dispatch(getFeaturesList(orgId, value, selectedPage));
  };

  const handleSearchClear = () => {
    dispatch(getFeaturesList(orgId, ''));
    setSearchText('');
    setSelectedPage(1);
  };

  const handleDesktopPageClick = (data: { selected: number }) => {
    dispatch(getFeaturesList(orgId, searchText, data.selected + 1));
    setSelectedPage(data.selected + 1);
  };
  const handleMobilePageClick = (item: any) => {
    dispatch(getFeaturesList(orgId, searchText, item));
    setSelectedPage(item);
  };

  const handleModalOpen = () => {
    dispatch(handleOrgLicenseModalPopup(true));
    navigate(`/organizations/${orgId}/licenses/bundles`);
  };

  useEffect(() => {
    dispatch(getFeaturesList(orgId, searchText, selectedPage));
  }, []);

  return (
    <Box h="100%" pl="30px" position="relative">
      <Box>
        <Flex alignItems="center" h="50px" m="15px 20px 0 0" justifyContent="space-between">
          <Box mr="10px">
            <Text fontWeight="700" fontSize="24px">
              <FormattedMessage id="features.message" />
            </Text>
          </Box>
          {!showEmptyMessage && (
            <Box data-testid="filter">
              <Search
                data={{ searchText, isSearchExpand: false }}
                handleClose={() => {}}
                handleClear={handleSearchClear}
                handleExpandClick={() => {}}
                handleSearch={handleSearchValue}
              />
            </Box>
          )}
        </Flex>
      </Box>
      {showEmptyMessage && (
        <Box data-testid="landing-screen">
          <LandingScreen handleClick={handleModalOpen} title="Features" newCreation={true} onlyTitle={false} />
        </Box>
      )}
      {isLoading ? <Loader isLoading={isLoading} /> : <OrgFeaturesListView searchText={searchText} />}
      {featuresData?.pagination && featureList && featureList.length > 0 && (
        <Flex
          w="100%"
          pl="30px"
          pr="20px"
          h="50px"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          bottom="5px"
          left="0"
          borderTop="1px solid var(--chakra-colors-chakra-border-color)"
        >
          <TableInformation selectedPage={selectedPage} data={featureList} pagination={paginationData} />
          <Box display={{ xs: 'none', xl: 'block' }}>
            <DesktopPaginate
              data={featureList}
              handleDesktopPageClick={handleDesktopPageClick}
              theme={theme}
              pagination={paginationData}
              selectedPage={selectedPage}
            />
          </Box>
          <Box display={{ xs: 'block', xl: 'none' }}>
            <MobilePagination
              data={featureList}
              selectedPage={selectedPage}
              numberOfPages={paginationData?.num_pages ?? 0}
              handleMobilePageClick={handleMobilePageClick}
            />
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default OrgFeatures;
