import { Box, Button, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Img from '../../assets/icons/continue.svg';
import { customColor } from '../../assets/styles/colors';
const Submit = ({ handleEmailSubmit, isEnable = false }: { handleEmailSubmit: () => void; isEnable: boolean }) => {
  const colors = customColor();
  return (
    <Box
      as="button"
      textAlign="center"
      width="100%"
      height="58px"
      borderRadius="14px"
      bg={isEnable ? colors.violetColor : colors.brandGrey600}
      cursor={isEnable ? 'pointer' : 'not-allowed'}
      onClick={isEnable ? handleEmailSubmit : () => false}
      position="absolute"
      bottom="0"
    >
      <Text
        color={isEnable ? 'dark.800' : 'light.1800'}
        opacity={isEnable ? '' : '50%'}
        fontSize={{ xs: '18px', md: '20px' }}
        fontWeight="500"
        lineHeight={{ xs: '26.46px', md: '29.4px' }}
      >
        <FormattedMessage id="continue.message" />
      </Text>
    </Box>
  );
};

export default Submit;
