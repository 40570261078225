import { createSlice } from '@reduxjs/toolkit';

import { dashboardResponse } from 'types/dashboard-types';

type InitialState = {
  statusLoading: boolean;

  statusError: boolean;

  dashboardResponse: dashboardResponse[];
};

export const initialState: InitialState = {
  dashboardResponse: [],

  statusLoading: false,

  statusError: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',

  initialState,

  reducers: {
    fetchDashboardLoading: (state) => {
      state.statusLoading = true;

      state.statusError = false;
    },

    fetchDashboardSuccess: (state, { payload }) => {
      state.dashboardResponse = payload;
    },

    fetchDashboardFail: (state) => {
      state.statusError = true;

      state.statusLoading = false;
    },
  },
});

export const {
  fetchDashboardSuccess,

  fetchDashboardLoading,

  fetchDashboardFail,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
