import React, { useEffect } from 'react';
import { Text, Box } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getSearchOrgNameDelete,
  handleEmptyInputs,
  handleOrgUsersForProvisiontatus,
  orgNameSearchValidation,
} from 'redux/organizations/Reducers/organizationSlice';
import { InputLabel } from 'components/InputField';

import { FormattedMessage, useIntl } from 'react-intl';
import CustomInputField from 'components/customInputField/CustomInputField';
import { handleCreateButtonEnableInModal } from 'redux/customInputFields/Reducers/CustomInputFieldsSlice';
import { postProvisionedData } from 'redux/organizations/Actions/organizationActions';
import { RootState } from 'redux/Store/store';
import { useParams } from 'react-router-dom';
import BannerMessage from '../../OrganizationCreatePage/BannerMessage/BannerMessage';

function OrgInfoProvisioningModalInputFields() {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { orgId } = useParams();
  const orgName = useAppSelector((state) => state.organization.SingleOrganizationsGetData);
  const orgNameValue = useAppSelector((state) => state.device.orgNameSearchValue);
  const orgNameSearchValidationValue = useAppSelector((state) => state.organization.orgNameSearchValidationValue);
  const getSearchValue = useAppSelector((state) => state.organization.getSearchOrgNameDeleteValue);
  const postClientApiData = useAppSelector((state) => state.clientAccess.postClientApiData);
  const clientPostDataFail = useAppSelector((state) => state.clientAccess.clientPostDataFail);
  const emptyInputValue = useAppSelector((state) => state.organization.emptyStatusInput);
  const postApiFailureMessage = useAppSelector((state) => state.organization.postApiFailureMessage);
  const usersData = useAppSelector((state: RootState) => state.orgUser?.usersApiData?.data);
  const handleModal = useAppSelector((state) => state.organization.openProvisionedModalValue);
  const toggleOrgUsersForProvision = useAppSelector((state) => state.organization.toggleOrgUsersForProvisionStatus);
  const postDeleteData = {
    status: 6,
  };
  useEffect(() => {
    if (usersData.find((item: any) => item.status?.id?.length > 0)) {
      dispatch(handleOrgUsersForProvisiontatus(true));
    } else {
      dispatch(handleOrgUsersForProvisiontatus(false));
    }
  }, [handleModal]);

  useEffect(() => {
    if (!clientPostDataFail && postClientApiData == true) {
      dispatch(postProvisionedData(postDeleteData, orgId));
    }
  }, [postClientApiData]);

  const handleDelete = (e: any) => {
    dispatch(getSearchOrgNameDelete(e.target.value.trim()));
    dispatch(orgNameSearchValidation(false));
    if (e.target.value.length > 0) {
      dispatch(handleEmptyInputs(false));
    }
  };
  const handleDeleteOnBlur = () => {
    if (getSearchValue.toLowerCase() == orgName.org_name.toLowerCase()) {
      dispatch(orgNameSearchValidation(false));
      dispatch(handleCreateButtonEnableInModal(true));
    } else {
      dispatch(orgNameSearchValidation(true));
      dispatch(handleCreateButtonEnableInModal(false));
    }
    if (getSearchValue.length == 0) {
      dispatch(handleEmptyInputs(true));
    } else {
      dispatch(handleEmptyInputs(false));
    }
  };

  return (
    <>
      <Text mb="10px" fontSize="16px" fontWeight="400" color="rgba(51, 51, 51, 0.6)" fontFamily="helvetica">
        <FormattedMessage
          id="provision_status_header.message"
          values={{
            b: (chunks) => <strong style={{ color: 'black' }}>{chunks}</strong>,
            name: orgName.org_name,
          }}
        />
      </Text>

      <Text fontSize="16px" fontWeight="400" color="rgba(51, 51, 51, 0.6)" fontFamily="helvetica">
        <FormattedMessage id="provision_status_header2.message" values={{ br: <br />, bullet: <>&#x25cf;</> }} />
      </Text>

      <Text fontSize="16px" fontWeight="400" color="rgba(51, 51, 51, 0.6)" mt="10px" fontFamily="helvetica">
        <FormattedMessage id="provision_status_header3.message" />
      </Text>

      <Box minH="80px" alignItems="center" data-testid="device_name_field_container" mt="10px">
        <Box mb="5px" width={{ xs: '85vw', smd: '89vw', md: '305px' }} data-testid="organization_name_label">
          <InputLabel name="name_of_the_org_to_provision" value={getSearchValue} />
        </Box>
        <Box>
          <CustomInputField
            name={'name_of_the_org_to_provision'}
            onKeyPress={undefined}
            maxLength={60}
            textSize="13px"
            placeholderSize="13px"
            handleOnChange={handleDelete}
            handleOnBlur={handleDeleteOnBlur}
            defaultValue={undefined}
            isEditable={true}
          />
          <Box fontSize="12px" color="#FB5654" mt="-40px">
            {orgNameSearchValidationValue && !emptyInputValue && <FormattedMessage id="invalid_org_name.message" />}
          </Box>
          <Box fontSize="12px" color="#FB5654" mt="-40px">
            {emptyInputValue && <FormattedMessage id="field_validation.message" />}
          </Box>
          <Box mt="10px">
            {postApiFailureMessage && <BannerMessage message={postApiFailureMessage} defaultMessage={''} />}
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default OrgInfoProvisioningModalInputFields;
